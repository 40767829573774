import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceReport {
    lookupService = new APIServiceLookUp();

    async getHomeCount(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}report/getHomeCount`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTopService(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}report/getTopService`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTopTech(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}report/getTopTech`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getTopComp(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}report/getTopComp`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getDeuPayments(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}report/getDeuPayments`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getUpcomingOrders(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}report/getUpcomingOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    getWeekOrders
}