import React, { useState } from 'react';

import ComponentReportCustomer from '../../components/reports/ComponentReportCustomer';
import ComponentReportLogs from '../../components/reports/ComponentReportLogs';
import ComponentReportOrders from '../../components/reports/ComponentReportOrders';
import ComponentReportTransaction from '../../components/reports/ComponentReportTransaction';
import ComponentReportVendor from '../../components/reports/ComponentReportVendor';

import TopBar from '../../core/widgets/ui/TopBar';

export default function PanelReports() {

    const [tab, setTab] = useState('customers');

    return (
        <div className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2">
                            Reports
                        </h2>
                        <TopBar />
                    </div>
                    <div class="w-full mt-2">
                        <div className="p-2 mt-2">
                            <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'customers' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('customers') }}>Customers</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'vendors' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('vendors') }}>Vendors</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'transaction' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('transaction') }}>Transaction</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'orders' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('orders') }}>Orders</button></li>
                                <li className="mx-2"><button className={`py-2 px-2 ${tab === 'logs' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('logs') }}>Logs</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 h-screen pt-3 px-3 pb-40">
                    {
                        (() => {
                            switch (tab) {
                                case 'customers':
                                    return (<ComponentReportCustomer />);
                                case 'vendors':
                                    return (<ComponentReportVendor />);
                                case 'transaction':
                                    return (<ComponentReportTransaction />);
                                case 'orders':
                                    return (<ComponentReportOrders />);
                                case 'logs':
                                    return (<ComponentReportLogs />);
                            }
                        })()
                    }
                </div>
            </div>
        </div>
    )

}