import React from 'react';
import ReactDOM from 'react-dom';

import { RecoilRoot } from 'recoil';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import FeatherIcon from 'feather-icons-react';

import './assets/styles/app.css';

import App from './app';
import ProgressBar from './core/widgets/ui/ProgressBar';

import './i18n';

const optionAlert = {
    position: positions.TOP_RIGHT,
    timeout: 6000,
    offset: '30px',
    transition: transitions.FADE
}

const AlertTemplate = ({ style, options, message, close }) => (
    <div className="rounded text-xs font-serif mt-10 mr-8 bg-white shadow">
        <p className={`px-4 py-2 flex ${(() => {
            switch (options.type) {
                case 'info':
                    return "text-blue-400";
                case 'success':
                    return "text-green-400";
                case 'error':
                    return "text-red-400";
                default:
                    return "text-gray-900";
            }
        })()}`}>
            <FeatherIcon icon="bell" size={18} />&nbsp;&nbsp;{message}
        </p>
        <ProgressBar type={options.type} secunds={6} />
    </div>
);

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <AlertProvider template={AlertTemplate} {...optionAlert}>
                <App />
            </AlertProvider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
);