import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';

import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';

import { useRecoilState } from 'recoil';
import { pathAtom, tokenAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';
import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceAsset from '../../services/serviceAsset';

export default function PanelAssets() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [token] = useRecoilState(tokenAtom);

    const { t } = useTranslation();

    const [mediaa, setMediaa] = useState([]);
    const [mediab, setMediab] = useState([]);
    const [mediac, setMediac] = useState([]);

    const [media, setMedia] = useState(null);
    const [formData, setFormData] = useState({});

    const [mtype, setMType] = useState('');
    const [lang, setLang] = useState('En');

    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    let assetsService = new ServiceAsset();

    const handeleSubmit = (e) => {
        e.preventDefault();
        setSubmit(true);
        var formDataC = new FormData();

        if (mtype !== "logos") {
            formDataC.append("type", formData.type);
        }
        if (mtype === "Ads" || mtype === "logos") {
            formDataC.append("url", formData.url);
        }
        formDataC.append("doc", media);
        formDataC.append("lang", lang);

        switch (mtype) {
            case 'Slider':
                assetsService.addSlider(formDataC, token).then((d) => {
                    document.getElementById("media-form").reset();
                    if (d.status) {
                        setSubmit(false);
                        setIsOpen(false);
                        setMedia(null);
                        setFormData({});
                        loadData(lang);
                        alert.show("New Media created.", { type: 'success' });
                    } else {
                        alert.show("Media creation error.", { type: 'error' });
                    }
                });
                break;
            case 'logos':
                assetsService.addLogo(formDataC, token).then((d) => {
                    document.getElementById("media-form").reset();
                    if (d.status) {
                        setSubmit(false);
                        setIsOpen(false);
                        setMedia(null);
                        setFormData({});
                        loadData(lang);
                        alert.show("New Media created.", { type: 'success' });
                    } else {
                        alert.show("Media creation error.", { type: 'error' });
                    }
                });
                break;
            case 'Ads':
                assetsService.addAd(formDataC, token).then((d) => {
                    document.getElementById("media-form").reset();
                    if (d.status) {
                        setSubmit(false);
                        setIsOpen(false);
                        setMedia(null);
                        setFormData({});
                        loadData(lang);
                        alert.show("New Media created.", { type: 'success' });
                    } else {
                        alert.show("Media creation error.", { type: 'error' });
                    }
                });
                break;
            default:
                setSubmit(false);
                setIsOpen(false);
                setMedia(null);
                setFormData({});
                loadData(lang);
                break;
        }
    }

    const deleteMedia = (id, type) => {
        let body = {
            "id": id,
        }
        switch (type) {
            case 'Slider':
                assetsService.delSlider(body, token).then((d) => {
                    if (d.status) {
                        loadData(lang);
                        alert.show("New Media deleted.", { type: 'success' });
                    } else {
                        alert.show("Media deleted error.", { type: 'error' });
                    }
                });
                break;
            case 'logos':
                assetsService.delLogo(body, token).then((d) => {
                    if (d.status) {
                        loadData(lang);
                        alert.show("New Media deleted.", { type: 'success' });
                    } else {
                        alert.show("Media deleted error.", { type: 'error' });
                    }
                });
                break;
            case 'Ads':
                assetsService.delAd(body, token).then((d) => {
                    if (d.status) {
                        loadData(lang);
                        alert.show("New Media deleted.", { type: 'success' });
                    } else {
                        alert.show("Media deleted error.", { type: 'error' });
                    }
                });
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const addMedia = (type) => {
        setMType(type);
        setIsOpen(true);
    }

    const toggle = () => {
        setPath({ main: path.main, sub: path.sub, isToggle: !path.isToggle });
    }

    const loadData = (lan) => {
        (async function () {
            var resA = await assetsService.getSlider({ 'lang': lan }, token);
            setMediaa(resA.data);

            var resB = await assetsService.getLogo({ 'lang': lan }, token);
            setMediab(resB.data);

            var resC = await assetsService.getAd({ 'lang': lan }, token);
            setMediac(resC.data);
        })();
    }

    useEffect(() => {
        loadData('En');
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add Media &nbsp;({mtype})
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                            setMedia(null);
                            document.getElementById("media-form").reset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handeleSubmit} id="media-form">
                        {
                            mtype !== "logos" && <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Select type<sup className="text-red-600">*</sup></label>
                                <select type="text" name="type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required onChange={handleOnChange}>
                                    <option value="">-- Select Type --</option>
                                    <option value="WEB">WEB</option>
                                    <option value="APP">APP</option>
                                </select>
                            </div>
                        }
                        {
                            (mtype === "Ads" || mtype === "logos") && <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Redirect link <span className="text-gray-400 text-xs">(optional)</span></label>
                                <input type="text" name="url" value={formData.url} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. https:xxxx" onChange={handleOnChange} />
                            </div>
                        }
                        <div className="mb-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Media file<sup className="text-red-600">*</sup>
                                </label>
                            </div>
                            {
                                media === null &&
                                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <span>Upload a file</span>
                                                <input type="file" className="sr-only" required onChange={(e) => setMedia(e.target.files[0])} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            PNG, JPG up to 512kb
                                        </p>
                                    </div>
                                </div>
                            }
                            {
                                media !== null &&
                                <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={URL.createObjectURL(media)} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {media.name}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={(e) => setMedia(e.target.files[0])} />
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "Add"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => toggle()}></motion.i> {t('pageA27')}
                            </h2>
                            <div className='flex'>
                                <Menu>
                                    <Menu.Button>
                                        <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-language mr-2"></i> Language (<span className='text-xs'>{lang}</span>)</button>
                                    </Menu.Button>
                                    <Transition
                                        enter="transition duration-100 ease-out"
                                        enterFrom="transform scale-95 opacity-0"
                                        enterTo="transform scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom="transform scale-100 opacity-100"
                                        leaveTo="transform scale-95 opacity-0">
                                        <Menu.Items>
                                            <div
                                                className='bg-white shadow-md rounded-md absolute' style={{ top: '52px', left: '-190px' }}>
                                                <div>
                                                    <ul className='flex flex-col items-start p-2'>
                                                        <Menu.Item>
                                                            <li className={`w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md ${lang === 'En' ? 'flex justify-between items-center' : ''}`} onClick={() => {
                                                                setLang('En');
                                                                loadData('En');
                                                            }}>
                                                                <div className='flex items-center text-base'>
                                                                    <p className={`text-sm font-serif ml-2 ${lang === 'En' ? 'font-medium text-green-600' : ''}`}>English</p>
                                                                </div>
                                                                {lang === 'En' && <i className={`las la-check-circle ${lang === 'En' ? 'font-medium text-green-600' : ''}`}></i>}
                                                            </li>
                                                        </Menu.Item>
                                                        <Menu.Item>
                                                            <li className={`w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md ${lang === 'Fr' ? 'flex justify-between items-center' : ''}`} onClick={() => {
                                                                setLang('Fr');
                                                                loadData('Fr');
                                                            }}>
                                                                <div className='flex items-center text-base'>
                                                                    <p className={`text-sm font-serif ml-2 ${lang === 'Fr' ? 'font-medium text-green-600' : ''}`}>French</p>
                                                                </div>
                                                                {lang === 'Fr' && <i className={`las la-check-circle ${lang === 'Fr' ? 'font-medium text-green-600' : ''}`}></i>}
                                                            </li>
                                                        </Menu.Item>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                                <TopBar />
                            </div>
                        </div>
                    </div>
                    <hr className='mt-2' />
                    <div className="intro-y mt-6 col-span-12">
                        <div className='flex justify-between items-center'>
                            <h1 className='text-lg font-medium text-black'>Hero Slider</h1>
                            <span className='text-sm text-blue-600 cursor-pointer' onClick={() => {
                                addMedia('Slider');
                            }}>Add <i class="las la-pen bg-blue-200 p-1 rounded-full text-blue-600"></i></span>
                        </div>
                        <div className='bg-white w-full shadow rounded-md p-4 mt-4'>
                            <ul className='w-full grid grid-cols-4 gap-2'>
                                {
                                    mediaa.length === 0 && <li className='px-2 col-span-4'>
                                        <div className='flex items-center justify-center'>
                                            <p className="text-sm font-medium text-blue-600">{t('misc5')}</p>
                                        </div>
                                    </li>
                                }
                                {
                                    mediaa.length !== 0 && mediaa.map((item) => {
                                        return <li className='px-2 col-span-1 bg-gray-100 border border-black rounded-md relative'>
                                            <motion.div whileHover={{ scale: 1.1 }} className="bg-red-200 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[10px]" onClick={() => {
                                                deleteMedia(item._id, 'Slider');
                                            }}>
                                                <i className="las la-trash text-lg text-red-800"></i>
                                            </motion.div>
                                            <motion.div whileHover={{ scale: 1.1 }} className="bg-green-200 text-green-800 text-sm px-4 py-1 font-medium rounded-full flex items-center justify-center cursor-pointer absolute top-[10px] right-[10px]">
                                                {item.type}
                                            </motion.div>
                                            <div className='flex items-center justify-center'>
                                                <img src={item.iconURL} alt="image" />
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="intro-y mt-6 col-span-12">
                        <div className='flex justify-between items-center'>
                            <h1 className='text-lg font-medium text-black'>sponsor / Partner Logos</h1>
                            <span className='text-sm text-blue-600 cursor-pointer' onClick={() => {
                                addMedia('logos');
                            }}>Add <i class="las la-pen bg-blue-200 p-1 rounded-full text-blue-600"></i></span>
                        </div>
                        <div className='bg-white w-full shadow rounded-md p-4 mt-4'>
                            <ul className='w-full grid grid-cols-4 gap-2'>
                                {
                                    mediab.length === 0 && <li className='px-2 col-span-4'>
                                        <div className='flex items-center justify-center'>
                                            <p className="text-sm font-medium text-blue-600">{t('misc5')}</p>
                                        </div>
                                    </li>
                                }
                                {
                                    mediab.length !== 0 && mediab.map((item) => {
                                        return <li className='px-2 col-span-1 bg-gray-100 border border-black rounded-md relative'>
                                            <motion.div whileHover={{ scale: 1.1 }} className="bg-red-200 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[10px]" onClick={() => {
                                                deleteMedia(item._id, 'logos');
                                            }}>
                                                <i className="las la-trash text-lg text-red-800"></i>
                                            </motion.div>
                                            <motion.div whileHover={{ scale: 1.1 }} className="bg-green-200 text-green-800 text-sm px-4 py-1 font-medium rounded-full flex items-center justify-center cursor-pointer absolute top-[10px] right-[10px]">
                                                {item.type}
                                            </motion.div>
                                            <div className='flex items-center justify-center'>
                                                <img src={item.iconURL} alt="image" />
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="intro-y mt-6 col-span-12">
                        <div className='flex justify-between items-center'>
                            <h1 className='text-lg font-medium text-black'>Banner Ads</h1>
                            <span className='text-sm text-blue-600 cursor-pointer' onClick={() => {
                                addMedia('Ads');
                            }}>Add <i class="las la-pen bg-blue-200 p-1 rounded-full text-blue-600"></i></span>
                        </div>
                        <div className='bg-white w-full shadow rounded-md p-4 mt-4'>
                            <ul className='w-full grid grid-cols-4 gap-2'>
                                {
                                    mediac.length === 0 && <li className='px-2 col-span-4'>
                                        <div className='flex items-center justify-center'>
                                            <p className="text-sm font-medium text-blue-600">{t('misc5')}</p>
                                        </div>
                                    </li>
                                }
                                {
                                    mediac.length !== 0 && mediac.map((item) => {
                                        return <li className='px-2 col-span-1 bg-gray-100 border border-black rounded-md relative'>
                                            <motion.div whileHover={{ scale: 1.1 }} className="bg-red-200 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer absolute bottom-[10px] right-[10px]" onClick={() => {
                                                deleteMedia(item._id, 'Ads');
                                            }}>
                                                <i className="las la-trash text-lg text-red-800"></i>
                                            </motion.div>
                                            <motion.div whileHover={{ scale: 1.1 }} className="bg-green-200 text-green-800 text-sm px-4 py-1 font-medium rounded-full flex items-center justify-center cursor-pointer absolute top-[10px] right-[10px]">
                                                {item.type}
                                            </motion.div>
                                            <div className='flex items-center justify-center'>
                                                <img src={item.iconURL} alt="image" />
                                            </div>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
    )

}