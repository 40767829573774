import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

export default function ComponentReportOrders() {
    return (
        <Scrollbars>
            <div>ComponentReportTransaction</div>
        </Scrollbars>
    )
}
