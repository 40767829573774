import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceCourse {

    lookupService = new APIServiceLookUp();

    async setCourse(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}course/setCourse`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateCourse(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}course/updateCourse`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateCourseSection(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}course/updateCourseSection`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setCourseC(body, token) {
        var url = `${API_URL}course/setCourseC`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async setCourseD(body, token) {
        var url = `${API_URL}course/setCourseD`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async getCourse(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}course/getCourse`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addMedia(body, token, onUploadProgress) {
        var url = `${API_URL}course/addMedia`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders }, onUploadProgress);
    }

}