import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, paramAtom, tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import Lottie from 'lottie-react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useAlert } from 'react-alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';
import axios from 'axios';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import * as XLSX from 'xlsx';

import TopBar from '../../core/widgets/ui/TopBar';

import animPage from '../../assets/anim/anim-pageload.json';
import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import fileIcon from '../../assets/image/file.png';

import ServiceAsset from '../../services/serviceAsset';
import { API_URL } from '../../services/models/AppConstent';

import 'react-google-flight-datepicker/dist/main.css';

export default function PanelEvents() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [param, setParam] = useRecoilState(paramAtom);
    const [token] = useRecoilState(tokenAtom);

    const { t } = useTranslation();

    const [events, setEvents] = useState([]);
    const [eventsList, setEventsList] = useState([]);
    const [eventsView, setEventsView] = useState([]);

    const [media, setMedia] = useState(null);

    const [tabNav, setTabNav] = useState('all');
    const [tab, setTab] = useState('local');
    const [query, setQuery] = useState('');

    const [progress, setProgress] = useState(0);

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    let assetsService = new ServiceAsset();

    const formVSchema = Yup.object().shape({
        title: Yup.string().required('This information is required'),
        certicate: Yup.string().required('This information is required'),
        trainer: Yup.string().required('This information is required'),
        description: Yup.string().required('This information is required'),
        outcome: Yup.string().required('This information is required'),
        date: Yup.string().required('This information is required'),
        place: Yup.string().required('This information is required'),
        location: Yup.string().required('This information is required'),
        fees: Yup.number().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm, setErrors } = useFormik({
        initialValues: {
            title: '',
            certicate: 'Y',
            trainer: '',
            description: '',
            outcome: '',
            date: '',
            place: '',
            location: '',
            videourl: '',
            fees: 0,
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (tab == "link" && values.videourl.length == 0) {
                setErrors({ ...errors, videourl: "Please enter a valid video URL" });
                alert.show("Please enter a valid video URL!", { type: 'error' });
            } else if (tab == "local" && media == null) {
                setErrors({ ...errors, videourl: "Please select atleast one video or image file" });
                alert.show("Please select atleast one video or image file!", { type: 'error' });
            } else {
                setSubmit(true);
                var formData = new FormData();
                formData.append("title", values.title);
                formData.append("certicate", values.certicate === 'N' ? false : true);
                formData.append("trainer", values.trainer);
                formData.append("description", values.description);
                formData.append("outcome", values.outcome);
                formData.append("date", values.date);
                formData.append("place", values.place);
                formData.append("location", values.location);
                formData.append("fees", values.fees);
                if (tab == "link") {
                    formData.append("type", "videolink");
                    formData.append("url", values.videourl);
                    fnSubmit(formData, token);
                } else {
                    const fileExtension = media.name.split(".")[1];
                    if (fileExtension == "png" || fileExtension == "jpg") {
                        formData.append("type", "image");
                        formData.append("media", media);
                        fnSubmit(formData, token);
                    } else if (fileExtension == "mp4") {
                        formData.append("type", "video");
                        formData.append("media", media);
                        fnSubmit(formData, token);
                    } else {
                        setErrors({ ...errors, videourl: "Invalid file extention" });
                        alert.show("Invalid file extention!", { type: 'error' });
                        setSubmit(false);
                    }
                }
            }
        }
    });

    const fnSubmit = (body, token) => {
        (async function () {
            var url = `${API_URL}misc/setEvent`;
            var requestHeaders = {
                'Content-type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `bearer ${token}`,
            };
            const response = await axios.post(url, body, {
                headers: requestHeaders,
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(progress);
                },
            });
            if (response.data.status) {
                resetForm();
                setMedia(null);
                setIsOpen(false);
                setTab('local');
                loadData();
                alert.show("New event created!", { type: 'success' });
            } else {
                alert.show("Server error, please try again!", { type: 'error' });
            }
            setSubmit(false);
        })();
    }

    const toggle = () => {
        setPath({ main: path.main, sub: path.sub, isToggle: !path.isToggle });
    }

    const changeTab = (val) => {
        setTabNav(val);
        let filtered = [];
        setQuery('');
        switch (val) {
            case "all":
                setEvents(eventsList);
                setEventsView(eventsList);
                break;
            case "processing":
                filtered = eventsList.filter(u => {
                    return u.status == 'processing';
                });
                setEvents(filtered);
                setEventsView(filtered);
                break;
            case "active":
                filtered = eventsList.filter(u => {
                    return u.status == 'active';
                });
                setEvents(filtered);
                setEventsView(filtered);
                break;
            default:
                break;
        }
    }

    const handleOnChangeSearch = (e) => {
        var { value, name } = e.target;
        setQuery(value);
        if (value.length > 0) {
            let list = eventsView.filter((u) => {
                return u.title.toLowerCase().includes(value.toLowerCase()) || u.trainer.toLowerCase().includes(value.toLowerCase()) || u.place.toLowerCase().includes(value.toLowerCase()) || u.location.toLowerCase().includes(value.toLowerCase())
            });
            setEvents(list);
        } else {
            setEvents(eventsView);
        }
    }

    const onDateChange = (start, end) => {
        setSDate(start);
        setEDate(end);
    }

    const getExportItems = () => {
        let items = [];
        events.forEach((item) => {
            items.push({
                title: item.title,
                certicate: item.certicate,
                trainer: item.trainer,
                description: item.description,
                outcome: item.outcome,
                date: item.date,
                place: item.place,
                location: item.location,
                fees: item.fees,
                bannerurl: item.media.thumbnail,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "events");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "events.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['title,certicate,trainer,description,outcome,date,place,location,fees,bannerurl,status,createdAt'];
        let usersCsv = getExportItems().reduce((rows, item) => {
            const { title, certicate, trainer, description, outcome, date, place, location, fees, bannerurl, status, createdAt } = item
            rows.push([title, certicate, trainer, description, outcome, date, place, location, fees, bannerurl, status, createdAt].join(','))
            return rows
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'events.csv',
            fileType: 'text/csv',
        });
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const deleteEvent = (id) => {
        let body = {
            id: id
        };
        assetsService.delEvent(body, token).then((res) => {
            if (res.status) {
                loadData();
                alert.show("Event deleted!", { type: 'success' });
            } else {
                alert.show("Server error, please try again!", { type: 'error' });
            }
        });
    }

    const onClickEvent = (id) => {
        setParam(id);
        setPath({ main: path.main, sub: 'sa3', isToggle: false });
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                'createdAt': {
                    '$gte': sdate,
                    '$lt': edate
                }
            };
            var res = await assetsService.getEvent(body, token);
            console.log(res.data);
            if (res['status']) {
                setTabNav('all');
                setTab('local');
                setEvents(res.data);
                setEventsList(res.data);
                setEventsView(res.data);
                setLoading(false);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Dialog open={isOpen} onClose={() => {
                setIsOpen(false);
                resetForm();
                setMedia(null);
                setTab('local');
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            {t('PanelEv2')} &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                            resetForm();
                            setMedia(null);
                            setTab('local');
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4 grid grid-cols-2 gap-2' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                        <div className="mb-2 col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv3')}<sup className="text-red-600">*</sup></label>
                            <input type="text" id="title" value={values.title} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.title && touched.title) && <p className='text-xs text-red-400 mt-1'>{errors.title}</p>}
                        </div>
                        <div className="mb-2 col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv4')}<sup className="text-red-600">*</sup></label>
                            <input type="text" id="trainer" value={values.trainer} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.trainer && touched.trainer) && <p className='text-xs text-red-400 mt-1'>{errors.trainer}</p>}
                        </div>
                        <div className="mb-2 col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv5')}<sup className="text-red-600">*</sup></label>
                            <input type="text" id="place" value={values.place} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.place && touched.place) && <p className='text-xs text-red-400 mt-1'>{errors.place}</p>}
                        </div>
                        <div className="mb-2 col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv6')}<sup className="text-red-600">*</sup></label>
                            <select id="certicate" value={values.certicate} onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                <option value="Y" selected>With Certicate</option>
                                <option value="N">Without Certificate</option>
                            </select>
                            {(errors.certicate && touched.certicate) && <p className='text-xs text-red-400 mt-1'>{errors.certicate}</p>}
                        </div>
                        <div className="mb-2 col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv7')}<sup className="text-red-600">*</sup></label>
                            <input type="date" id="date" value={values.date} min={Moment().add(1, 'day').format('yyyy-MM-DD')} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.date && touched.date) && <p className='text-xs text-red-400 mt-1'>{errors.date}</p>}
                        </div>
                        <div className="mb-2 col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv8')}<sup className="text-red-600">*</sup></label>
                            <input type="text" id="location" value={values.location} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.location && touched.location) && <p className='text-xs text-red-400 mt-1'>{errors.location}</p>}
                        </div>
                        <div className="mb-2 col-span-1">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv9')} ($)<sup className="text-red-600">*</sup></label>
                            <input type="number" id="fees" value={values.fees} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.fees && touched.fees) && <p className='text-xs text-red-400 mt-1'>{errors.fees}</p>}
                        </div>
                        <div className="mb-2 col-span-2">
                            <div class="w-full">
                                <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                    <li className="mx-2"><button type='button' className={`py-2 px-2 ${tab === 'local' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('local') }}>Local file</button></li>
                                    <li className="mx-2"><button type='button' className={`py-2 px-2 ${tab === 'link' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('link') }}>Video Link</button></li>
                                </ul>
                            </div>
                        </div>
                        <div className="mb-2 col-span-2">
                            {
                                (media === null && tab == "local") && <>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                    <span>Upload a file</span>
                                                    <input type="file" className="sr-only" required onChange={(e) => setMedia(e.target.files[0])} />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                            <p className="pl-1 text-xs text-gray-400">.jpg, .png and .mp4 only</p>
                                        </div>
                                    </div>
                                    {errors.videourl && <p className='text-xs text-red-400 mt-1'>{errors.videourl}</p>}
                                </>
                            }
                            {
                                (media !== null && tab == "local") && <>
                                    <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                        <div className="w-0 flex-1 flex items-center">
                                            <img src={fileIcon} alt="Icon Template" className='w-10 h-10' />
                                            <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                                {media.name}&nbsp;
                                                <i className="las la-link text-gray-400 text-lg"></i>
                                            </span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                <span>Replace</span>
                                                <input type="file" className="sr-only" onChange={(e) => setMedia(e.target.files[0])} />
                                            </label>
                                        </div>
                                    </div>
                                    {errors.videourl && <p className='text-xs text-red-400 mt-1'>{errors.videourl}</p>}
                                </>
                            }
                            {
                                submit && <div className='mt-3'>
                                    <p className='text-xs text-gray-400'>Uploading....</p>
                                    <div className='w-full bg-gray-300 mt-1' style={{ height: '4px' }}>
                                        <div style={{ width: `${progress}%`, transitionDuration: `4s` }} className="h-full transition-all bg-green-400"></div>
                                    </div>
                                </div>
                            }
                            {
                                tab == "link" && <div>
                                    <label className="block mb-2 text-sm font-medium text-gray-600">Video URL<sup className="text-red-600">*</sup></label>
                                    <input type="text" id="videourl" value={values.videourl} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. https://www.youtube.com/dhsjhkjhdsjahu" onChange={handleChange} />
                                    {(errors.videourl && touched.videourl) && <p className='text-xs text-red-400 mt-1'>{errors.videourl}</p>}
                                </div>
                            }
                        </div>
                        <div className="mb-2 col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv10')}<sup className="text-red-600">*</sup></label>
                            <textarea type="text" id="description" value={values.description} className="bg-gray-50 border h-32 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.description && touched.description) && <p className='text-xs text-red-400 mt-1'>{errors.description}</p>}
                        </div>
                        <div className="mb-2 col-span-2">
                            <label className="block mb-2 text-sm font-medium text-gray-600">{t('PanelEv11')}<sup className="text-red-600">*</sup></label>
                            <textarea type="text" id="outcome" value={values.outcome} className="bg-gray-50 border h-32 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.outcome && touched.outcome) && <p className='text-xs text-red-400 mt-1'>{errors.outcome}</p>}
                        </div>
                        <div className='flex items-end justify-end col-span-2'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "ADD"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10">
                                <h2 class="text-2xl font-medium truncate ml-2">
                                    <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => toggle()}></motion.i>  {t('PanelStr8')}
                                </h2>
                                <div className='flex'>
                                    <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4" onClick={() => {
                                        setIsOpen(true);
                                    }}><i className="las la-plus mr-2"></i> {t('PanelEv1')}</button>
                                    <TopBar />
                                </div>
                            </div>
                            <div class="w-full mt-2">
                                <div className="p-2 mt-2">
                                    <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                        <li className="mx-2"><button className={`py-2 px-2 ${tabNav === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tabNav === 'processing' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('processing') }}>Processing</button></li>
                                        <li className="mx-2"><button className={`py-2 px-2 ${tabNav === 'active' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('active') }}>Active</button></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 mt-4 px-2">
                            <div className="w-full shadow-md rounded-md border">
                                <table className="min-w-full divide-y divide-gray-200 px-4">
                                    <thead className="bg-gray-50">
                                        <tr className=''>
                                            <th scope="col" className="border-b border-gray-200" colSpan={9}>
                                                <div className='w-full grid grid-cols-12 gap-2'>
                                                    <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                        <div className='col-span-4'>
                                                            <RangeDatePicker
                                                                startDate={sdate}
                                                                endDate={edate}
                                                                minDate={new Date(2022, 8, 1)}
                                                                disabled={false}
                                                                onCloseCalendar={(e) => {
                                                                    loadData();
                                                                }}
                                                                onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                                className=""
                                                                startWeekDay="monday"
                                                            />
                                                        </div>
                                                        <div className='w-full flex col-span-8 items-center'>
                                                            <div className='w-[80px]'>
                                                                <p>{t('misc4')} :</p>
                                                            </div>
                                                            <input type="text" value={query} onChange={handleOnChangeSearch} name='search' placeholder="Search by title, trainer, place and location." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                        </div>
                                                    </form>
                                                    <div className='col-span-1 flex items-center'>
                                                        <Menu>
                                                            <Menu.Button>
                                                                <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> {t('misc1')} </button>
                                                            </Menu.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0">
                                                                <Menu.Items>
                                                                    <div
                                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                        <div>
                                                                            <ul className='flex flex-col items-start p-2'>
                                                                                <Menu.Item>
                                                                                    <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                        exportToExcel();
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <p className="text-sm font-serif ml-2 font-normal">{t('misc2')}</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                                <Menu.Item>
                                                                                    <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                        exportToCsv();
                                                                                    }}>
                                                                                        <div className='flex items-center text-base'>
                                                                                            <p className="text-sm font-serif ml-2 font-normal">{t('misc3')}</p>
                                                                                        </div>
                                                                                    </li>
                                                                                </Menu.Item>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Menu.Items>
                                                            </Transition>
                                                        </Menu>
                                                    </div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Event Banner
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Event Information
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                certicate
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                date
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                Location Details
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                FEES
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                STATUS
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                ENROLLMENTS
                                            </th>
                                            <th scope="col" className="px-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                -
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            events.length !== 0 && events.map((event) => {
                                                return <tr className="cursor-pointer hover:bg-gray-100">
                                                    <td className="pl-6 py-4 whitespace-nowrap w-[300px]">
                                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                                            <img src={event.status === "processing" ? 'https://via.placeholder.com/260x160' : event.media.thumbnail} alt='Thumbnail image' className='rounded w-[260px] h-[160px] m-0 p-0' />
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 max-w-[140px]">
                                                        <div className="text-sm font-bold text-gray-900">
                                                            <div className='flex flex-col items-start justify-start'>
                                                                <p className='uppercase'>{event.title}</p>
                                                                <p className='text-sm text-gray-800'>Trainer: {event.trainer}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                                            {event.certicate ? 'With Certificate' : 'Without Certificate'}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                                            {Moment(event.date).format('DD, MMM yy').toString()}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 max-w-[100px]">
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {event.place}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                Location: {event.location}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-bold text-green-700 uppercase">
                                                            {event.fees} $
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap w-[160px]">
                                                        <div className={`text-sm font-bold uppercase text-center rounded-full py-1 ${event.status == "processing" ? 'text-blue-700 bg-blue-200' : 'text-green-700 bg-green-200'}`}>
                                                            {event.status}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                                            {event.nop}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <Popover className="relative">
                                                            <Popover.Button>
                                                                <motion.i whileHover={{ scale: 1.1 }} className="las la-ellipsis-v text-xl"></motion.i>
                                                            </Popover.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0">
                                                                <Popover.Panel>
                                                                    <div
                                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '6px', left: '-136px' }}>
                                                                        <div>
                                                                            <ul className='flex flex-col items-start p-2'>
                                                                                <li className='w-32 cursor-pointer hover:bg-green-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    onClickEvent(event._id)
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-list-alt text-lg"></i>
                                                                                        <p className='text-sm font-serif ml-2'>View</p>
                                                                                    </div>
                                                                                </li>
                                                                                <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    deleteEvent(event._id);
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-trash text-lg"></i>
                                                                                        <p className='text-sm font-serif ml-2'>Delete</p>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Popover.Panel>
                                                            </Transition>
                                                        </Popover>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            events.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap" colSpan="9">
                                                    <div className="text-sm text-blue-600 text-center">
                                                        No data found.
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    );
}
