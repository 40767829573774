import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { pathAtom, tokenAtom } from '../../core/config/atoms';

import Moment from 'moment';
import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';

import TopBar from '../../core/widgets/ui/TopBar';
import ServiceReport from '../../services/serviceReport';

import animPage from '../../assets/anim/anim-pageload.json';

export default function PanelOverview() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [token] = useRecoilState(tokenAtom);

    const [services, setServices] = useState([]);
    const [techs, setTechs] = useState([]);
    const [copms, setComps] = useState([]);
    const [porders, setPOrders] = useState([]);
    const [orders, setOrders] = useState([]);

    const { t } = useTranslation();

    const [sdate, setSDate] = useState(Moment().toDate());
    const [edate, setEDate] = useState(Moment().add(7, 'days').toDate());

    const [loading, setLoading] = useState(true);

    const [homeCounts, setHomeCounts] = useState({});

    const reportService = new ServiceReport();

    const toggle = () => {
        setPath({ main: path.main, sub: path.sub, isToggle: !path.isToggle });
    }

    const getPBGChip = (status) => {
        switch (status) {
            case 'notpaid':
                return 'bg-red-100 text-red-800';
            case 'paid':
                return 'bg-green-100 text-green-800';
            case 'partial':
                return 'bg-yellow-100 text-yellow-800';
            default:
                return 'bg-blue-100 text-blue-800';
        }
    }

    const getChipColorA = (status) => {
        switch (status) {
            case "quotation":
            case "sitevisit":
            case "booked":
                return "bg-blue-100 text-blue-800";
            case "cancelled":
                return "bg-red-100 text-red-800";
            default:
                return "bg-green-100 text-green-800";
        }
    }

    const loadData = () => {
        (async function () {
            let body = {
                sdate: sdate.toISOString(),
                edate: edate.toISOString(),
            }

            const [homeRes, servicesRes, techsRes, compsRes, paymentRes, orderRes] = await Promise.all([
                reportService.getHomeCount({}, token),
                reportService.getTopService({}, token),
                reportService.getTopTech({}, token),
                reportService.getTopComp({}, token),
                reportService.getDeuPayments({}, token),
                reportService.getUpcomingOrders(body, token)
            ]);

            setHomeCounts(homeRes.data);
            setServices(servicesRes.data);
            setTechs(techsRes.data);
            setComps(compsRes.data);
            setPOrders(paymentRes.data);
            setOrders(orderRes.data);

            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <div className="intro-y flex items-center justify-between h-10">
                <h2 className="text-2xl font-medium truncate ml-2">
                    <motion.i whileHover={{ scale: 1.2 }} className="las la-bars cursor-pointer" onClick={() => toggle()}></motion.i> {t('pageA1')}
                </h2>
                <TopBar />
            </div>
            <hr className='mt-2' />
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-4 lg:grid-cols-10 mt-6 mb-5 gap-2 xl:gap-5 ml-2">
                        <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                            <div className="shadow bg-green-200 rounded-lg">
                                <div className="box p-5">
                                    <div className="flex items-center">
                                        <i className="las la-dollar-sign text-4xl 2xl:text-6xl text-green-600"></i>
                                        <div className="ml-auto text-right">
                                            <div className="text-2xl font-bold leading-8">$ {homeCounts.sales.toFixed(2)}</div>
                                            <div className="text-xs text-gray-600 mt-1">{t('pageA2')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                            <div className="shadow bg-primary-200 rounded-lg">
                                <div className="box p-5">
                                    <div className="flex items-center">
                                        <i className="las la-receipt text-4xl 2xl:text-6xl text-primary"></i>
                                        <div className="ml-auto text-right">
                                            <div className="text-2xl font-bold leading-8">{homeCounts.orders}</div>
                                            <div className="text-xs text-gray-600 mt-1">{t('pageA3')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                            <div className="shadow bg-red-200 rounded-lg">
                                <div className="box p-5">
                                    <div className="flex items-center">
                                        <i className="las la-calendar text-4xl 2xl:text-6xl text-red-600"></i>
                                        <div className="ml-auto text-right">
                                            <div className="text-2xl font-bold leading-8">$ {homeCounts.deu.toFixed(2)}</div>
                                            <div className="text-xs text-gray-600 mt-1">{t('pageA4')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                            <div className="shadow bg-yellow-200 rounded-lg">
                                <div className="box p-5">
                                    <div className="flex items-center">
                                        <i className="las la-city text-4xl 2xl:text-6xl text-yellow-500"></i>
                                        <div className="ml-auto text-right">
                                            <div className="text-2xl font-bold leading-8">{homeCounts.comp}</div>
                                            <div className="text-xs text-gray-600 mt-1">{t('pageA5')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                        <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                            <div className="shadow bg-blue-200 rounded-lg">
                                <div className="box p-5">
                                    <div className="flex items-center">
                                        <i className="las la-user-tie text-4xl 2xl:text-6xl text-blue-500"></i>
                                        <div className="ml-auto text-right">
                                            <div className="text-2xl font-bold leading-8">{homeCounts.tech}</div>
                                            <div className="text-xs text-gray-600 mt-1">{t('pageA6')}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                        <div className="col-span-4 cursor-pointer">
                            <div className="shadow bg-white rounded-lg p-4">
                                <h3 className='text-lg font-sans font-medium'>{t('pageA7')}</h3>
                                <hr className='my-2' />
                                {
                                    services.length == 0 && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                                        <p className='text-center'>{t('pageA12')}</p>
                                    </div>
                                }
                                {
                                    services.map((service) => {
                                        return <div className='w-full flex my-2'>
                                            <img src={service.categorie[0].iconURL} alt='Banner image' className='rounded w-[40px] h-[40px]' />
                                            <div className='flex flex-col items-start justify-start ml-2'>
                                                <h1 className='text-sm font-medium text-black'>{service.title}</h1>
                                                <p className='text-xs font-medium text-gray-600'>{service.categorie[0].title}</p>
                                            </div>
                                            <div className='flex-grow'></div>
                                            <div className='text-xs bg-yellow-200 text-yellow-600 h-[20px] px-2 rounded-full'>
                                                <p><i className="las la-star text-sm"></i> {service.rateing.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-span-4 cursor-pointer">
                            <div className="shadow bg-white rounded-lg p-4">
                                <h3 className='text-lg font-sans font-medium'>{t('pageA8')}</h3>
                                <hr className='my-2' />
                                {
                                    techs.length == 0 && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                                        <p className='text-center'>{t('pageA13')}</p>
                                    </div>
                                }
                                {
                                    techs.map((tech) => {
                                        return <div className='w-full flex my-2'>
                                            <img src={tech.profile[0].fileurl} alt='Banner image' className='rounded w-[40px] h-[40px]' />
                                            <div className='flex flex-col items-start justify-start ml-2'>
                                                <h1 className='text-sm font-medium text-black'>{tech.fname} {tech.lname}</h1>
                                                <p className='text-xs font-medium text-gray-600'>+243 {tech.phone}</p>
                                            </div>
                                            <div className='flex-grow'></div>
                                            <div className='text-xs bg-yellow-200 text-yellow-600 h-[20px] px-2 rounded-full'>
                                                <p><i className="las la-star text-sm"></i> {tech.rating.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-span-4 cursor-pointer">
                            <div className="shadow bg-white rounded-lg p-4">
                                <h3 className='text-lg font-sans font-medium'>{t('pageA9')}</h3>
                                <hr className='my-2' />
                                {
                                    copms.length == 0 && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                                        <p className='text-center'>{t('pageA14')}</p>
                                    </div>
                                }
                                {
                                    copms.map((tech) => {
                                        return <div className='w-full flex my-2'>
                                            <img src={tech.profile[0].fileurl} alt='Banner image' className='rounded w-[40px] h-[40px]' />
                                            <div className='flex flex-col items-start justify-start ml-2'>
                                                <h1 className='text-sm font-medium text-black'>{tech.fname} {tech.lname}</h1>
                                                <p className='text-xs font-medium text-gray-600'>+243 {tech.phone}</p>
                                            </div>
                                            <div className='flex-grow'></div>
                                            <div className='text-xs bg-yellow-200 text-yellow-600 h-[20px] px-2 rounded-full'>
                                                <p><i className="las la-star text-sm"></i> {tech.rating.toFixed(2)}</p>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                        <div className="col-span-12 cursor-pointer">
                            <div className="shadow bg-white rounded-lg p-4">
                                <h3 className='text-lg font-sans font-medium'>{t('pageA10')} | {Moment(sdate).format('MMM DD, YYYY')}</h3>
                                <hr className='my-2' />
                                <table className="min-w-full divide-y divide-gray-200 px-4">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA15')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA16')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA17')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA18')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA19')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA20')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA21')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            porders.length !== 0 && porders.map((order, index) => {
                                                return <tr className="cursor-pointer hover:bg-gray-100" key={index}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                                            Bookind Date: {Moment(order.bookingDate).format('MMM DD, YYYY')}<br />
                                                            Orders ID: {order.oid}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {order.categorie[0].title}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                {order.sub[0].title}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <div>
                                                            <div className="text-sm font-bold text-gray-900">
                                                                {order.user[0].fname} {order.user[0].lname}
                                                            </div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {order.user[0].email}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                Ph.: +243 {order.user[0].phone}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {order.address.title}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                {order.address.address}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getPBGChip(order.pstatus)}`}>
                                                            {order.pstatus}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(order.status)}`}>
                                                            {order.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-red-100 text-red-800">
                                                            $ {order.invoices.pending}
                                                        </span>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            porders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap" colSpan="7">
                                                    <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                                                        <p className='text-center'>{t('pageA23')}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                        <div className="col-span-12 cursor-pointer">
                            <div className="shadow bg-white rounded-lg p-4">
                                <h3 className='text-lg font-sans font-medium'>{t('pageA11')} | {Moment(sdate).format('MMM DD, YYYY')} - {Moment(edate).format('MMM DD, YYYY')}</h3>
                                <hr className='my-2' />
                                <table className="min-w-full divide-y divide-gray-200 px-4">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA15')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA16')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA17')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA18')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA19')}
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                {t('pageA20')}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            orders.length !== 0 && orders.map((order, index) => {
                                                return <tr className="cursor-pointer hover:bg-gray-100" key={index}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div className="text-sm font-bold text-gray-900 uppercase">
                                                            Bookind Date: {Moment(order.bookingDate).format('MMM DD, YYYY')}<br />
                                                            Orders ID: {order.oid}
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {order.categorie[0].title}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                {order.sub[0].title}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                        <div>
                                                            <div className="text-sm font-bold text-gray-900">
                                                                {order.user[0].fname} {order.user[0].lname}
                                                            </div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {order.user[0].email}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                Ph.: +243 {order.user[0].phone}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <div>
                                                            <div className="text-sm font-medium text-gray-900">
                                                                {order.address.title}
                                                            </div>
                                                            <div className="text-sm text-gray-500">
                                                                {order.address.address}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getPBGChip(order.pstatus)}`}>
                                                            {order.pstatus}
                                                        </span>
                                                    </td>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(order.status)}`}>
                                                            {order.status}
                                                        </span>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        {
                                            orders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                                    <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                                                        <p className='text-center'>{t('pageA22')}</p>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'></div>
                </Scrollbars>
            }
        </div>
    )

}