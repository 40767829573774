import React, { useState } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom, tokenAtom } from '../../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';
import { Dialog } from '@headlessui/react';
import Lottie from 'lottie-react';
import { Document, Page, pdfjs } from 'react-pdf';

import ServiceUser from '../../../services/serviceUser';
import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

const allowedExtensions = ["png", "jpeg", "pdf"];

export default function PanelCompanyAdd() {
    const [path, setPath] = useRecoilState(pathAtom);
    const [token] = useRecoilState(tokenAtom);

    const [picFile, setPicFile] = useState(null);
    const [rccmFile, setRccmFile] = useState(null);
    const [idFile, setIDFile] = useState(null);
    const [pFile, setPFile] = useState(null);

    const [url, setURL] = useState('');
    const [FName, setFName] = useState('');

    const [submit, setSubmit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isPic, setIsPic] = useState(false);

    const alert = useAlert();

    const userService = new ServiceUser();

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const formVSchema = Yup.object().shape({
        firstName: Yup.string().required('This information is required'),
        lastName: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
        tNumber: Yup.string().required('This information is required'),
        occupation: Yup.string().required('This information is required'),
        address: Yup.string().required('This information is required'),
        dob: Yup.date().required('This information is required'),
        cname: Yup.string().required('This information is required'),
        caddr: Yup.string().required('This information is required'),
        disc: Yup.string().required('This information is required'),
        wlink: Yup.string().url('Please enter a valid url')
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset } = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            occupation: '',
            address: '',
            tNumber: '',
            dob: '',
            cname: '',
            caddr: '',
            wlink: '',
            disc: '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (idFile != null && picFile != null && rccmFile != null) {
                setSubmit(true);
                let body = {
                    "fname": values.firstName,
                    "lname": values.lastName,
                    "email": values.email,
                    "phone": values.phone,
                    "occupation": values.occupation,
                    "address": values.address,
                    "type": "company",
                    "tax": values.tNumber,
                    "dob": values.dob,
                    "companyInfo": {
                        name: values.cname,
                        address: values.caddr,
                    },
                    "clientInfo": {},
                    "plink": values.wlink,
                    "disc": values.disc,
                }
                userService.setUser(body, token).then((res) => {
                    if (res.status) {
                        let user = res.data;
                        let promis = [];

                        var formDataA = new FormData();

                        formDataA.append("path", 'users/' + user._id + '/profile/');
                        formDataA.append("title", 'LOGO');
                        formDataA.append("type", 'profile');
                        formDataA.append("id", user._id);
                        formDataA.append("file", picFile);

                        var formDataB = new FormData();

                        formDataB.append("path", 'users/' + user._id + '/docs/');
                        formDataB.append("title", 'ID');
                        formDataB.append("type", 'docs');
                        formDataB.append("id", user._id);
                        formDataB.append("file", idFile);

                        var formDataD = new FormData();

                        formDataD.append("path", 'users/' + user._id + '/docs/');
                        formDataD.append("title", 'RCCM Document');
                        formDataD.append("type", 'docs');
                        formDataD.append("id", user._id);
                        formDataD.append("file", rccmFile);

                        promis.push(userService.uploadUserFile(formDataA, token));
                        promis.push(userService.uploadUserFile(formDataB, token));
                        promis.push(userService.uploadUserFile(formDataD, token));

                        if (pFile != null) {
                            var formDataC = new FormData();

                            formDataC.append("path", 'users/' + user._id + '/docs/');
                            formDataC.append("title", 'Portfolio');
                            formDataC.append("type", 'docs');
                            formDataC.append("id", user._id);
                            formDataC.append("file", pFile);

                            promis.push(userService.uploadUserFile(formDataC, token));
                        }

                        Promise.all(promis).then((responses) => {
                            setSubmit(false);
                            alert.show("New technician added!", { type: 'success' });
                            goBack();
                        });
                    } else {
                        setSubmit(false);
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                });
            } else {
                setSubmit(false);
                alert.show("Please select all the mandatory document!", { type: 'error' });
            }
        },
    })

    const goBack = () => {
        setPath({ main: path.main, sub: 'sa3', isToggle: true });
    }

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        switch (name) {
            case 'pic':
                setPicFile(inputFile);
                break;
            case 'nID':
                setIDFile(inputFile);
                break;
            case 'rccm':
                setRccmFile(inputFile);
                break;
            case 'pFile':
                setPFile(inputFile);
                break;
        }
    };

    const createImageUrl = (buffer, type) => {
        const blob = new Blob([buffer], { type })
        const urlCreator = window.URL || window.webkitURL
        const imageUrl = urlCreator.createObjectURL(blob)
        return imageUrl
    }

    const getTextureFile = async (File) => {
        const file = File;
        const { type } = file;
        const buffer = await file.arrayBuffer();
        const imageUrl = createImageUrl(buffer, type);
        return imageUrl;
    }

    const handleViewDoc = (type) => {
        setFName(type);
        let fileExtension = "";
        switch (type) {
            case "doca":
                fileExtension = pFile.type.split("/")[1];
                if (fileExtension == 'pdf') {
                    setIsPic(false);
                    setIsOpen(true);
                } else {
                    getTextureFile(pFile).then((res) => {
                        setURL(res);
                        setIsPic(true);
                        setIsOpen(true);
                    });
                }
                break;
            case "docb":
                fileExtension = rccmFile.type.split("/")[1];
                if (fileExtension == 'pdf') {
                    setIsPic(false);
                    setIsOpen(true);
                } else {
                    getTextureFile(rccmFile).then((res) => {
                        setURL(res);
                        setIsPic(true);
                        setIsOpen(true);
                    });
                }
                break;
            case "docc":
                fileExtension = picFile.type.split("/")[1];
                if (fileExtension == 'pdf') {
                    setIsPic(false);
                    setIsOpen(true);
                } else {
                    getTextureFile(picFile).then((res) => {
                        setURL(res);
                        setIsPic(true);
                        setIsOpen(true);
                    });
                }
                break;
            case "docd":
                fileExtension = pFile.type.split("/")[1];
                if (fileExtension == 'pdf') {
                    setIsPic(false);
                    setIsOpen(true);
                } else {
                    getTextureFile(pFile).then((res) => {
                        setURL(res);
                        setIsPic(true);
                        setIsOpen(true);
                    });
                }
                break;
        }

    }

    const getFile = () => {
        switch (FName) {
            case "doca":
                return pFile;
            case "docb":
                return rccmFile;
            case "docc":
                return picFile;
            case "docd":
                return pFile;
        }
    }

    function onDocumentLoadSuccess() {
        //Nothing to do.
    }

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-4xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Document viewer &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                        }}>
                            <i className="las la-times text-sm"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    {
                        isPic && <div>
                            <img src={url} alt='Document' className='w-full rounded border-2' />
                        </div>
                    }
                    {
                        !isPic &&
                        <div className="w-[860px] h-[700px] overflow-scroll">
                            <Document
                                file={getFile()}
                                onLoadSuccess={onDocumentLoadSuccess} className="w-full h-960">
                                <Page pageNumber={1} />
                            </Document>
                        </div>
                    }
                </div>
            </Dialog>
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2 p-3 overflow-y-scroll">
                    <div className='col-span-12'>
                        <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                            <i className="las la-long-arrow-alt-left text-xl"></i>
                            <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all company</h4>
                        </div>
                        <div className="intro-y mt-4">
                            <h3 className="text-2xl font-sans font-bold text-gray-800">Company add</h3>
                            <p className="mt-1 text-sm text-gray-600 mb-2">
                                Please fill all information related to company.
                            </p>
                            <hr />
                        </div>
                    </div>
                    <div className='col-span-12'>
                        <form onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
                            <div className="mt-4 grid grid-cols-12 gap-6">
                                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                    <div className="shadow">
                                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Basic Information</h3>
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">First name<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="firstName" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="First name" value={values.firstName} />
                                                    {
                                                        (errors.firstName && touched.firstName) && <p className='text-xs text-red-400 mt-1'>{errors.firstName}</p>
                                                    }
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Last name<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="lastName" onChange={handleChange} autoComplete="family-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Last name" value={values.lastName} />
                                                    {(errors.lastName && touched.lastName) && <p className='text-xs text-red-400 mt-1'>{errors.lastName}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Email address</label>
                                                    <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Email address" value={values.email} />
                                                    {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Phone number<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="phone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 012345689" maxLength="10" minLength="10" value={values.phone} />
                                                    {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Occupation / Designation<sup className="text-red-600">*</sup></label>
                                                    <div className="relative">
                                                        <input type="text" id="occupation" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. IT Service" value={values.occupation} />
                                                    </div>
                                                    {(errors.occupation && touched.occupation) && <p className='text-xs text-red-400 mt-1'>{errors.occupation}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Tax Number<sup className="text-red-600">*</sup></label>
                                                    <div className="relative">
                                                        <input type="text" id="tNumber" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 123456781212" value={values.tNumber} />
                                                    </div>
                                                    {(errors.tNumber && touched.tNumber) && <p className='text-xs text-red-400 mt-1'>{errors.tNumber}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Date of incorporation<sup className="text-red-600">*</sup></label>
                                                    <div className="relative">
                                                        <input type="date" id="dob" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" value={values.dob} />
                                                    </div>
                                                    {(errors.dob && touched.dob) && <p className='text-xs text-red-400 mt-1'>{errors.dob}</p>}
                                                </div>

                                                <div className="col-span-6">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Street address<sup className="text-red-600">*</sup></label>
                                                    <textarea id="address" onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Street address" value={values.address}></textarea>
                                                    {(errors.address && touched.address) && <p className='text-xs text-red-400 mt-1'>{errors.address}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="intro-y hidden sm:block">
                                <div className="py-5">
                                    <div className="border-t border-gray-200"></div>
                                </div>
                            </div>
                            <div className="mt-4 grid grid-cols-12 gap-6">
                                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                    <div className="shadow">
                                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                            <div className="grid grid-cols-12 gap-6">

                                                <div className="col-span-12">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Company Information</h3>
                                                </div>

                                                <div className="col-span-6 sm:col-span-6">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Comapny name<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="cname" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Company name" value={values.cname} />
                                                    {(errors.cname && touched.cname) && <p className='text-xs text-red-400 mt-1'>{errors.cname}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-6">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Company Website Link <span className="text-gray-600 text-[8px]">(optional)</span></label>
                                                    <input type="text" id="wlink" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="https://companyname.com" value={values.wlink} />
                                                </div>

                                                <div className="col-span-12 sm:col-span-12">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Short description <sup className="text-red-600">*</sup></label>
                                                    <textarea type="text" id="disc" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. About company" value={values.disc}></textarea>
                                                    {(errors.disc && touched.disc) && <p className='text-xs text-red-400 mt-1'>{errors.disc}</p>}
                                                </div>

                                                <div className="col-span-12 sm:col-span-12">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Company Address <sup className="text-red-600">*</sup></label>
                                                    <textarea type="text" id="caddr" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-20 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. Company address" value={values.caddr}></textarea>
                                                    {(errors.caddr && touched.caddr) && <p className='text-xs text-red-400 mt-1'>{errors.caddr}</p>}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="intro-y hidden sm:block">
                                <div className="py-5">
                                    <div className="border-t border-gray-200"></div>
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="grid grid-cols-12 gap-6">
                                    <div className="intro-y mt-5 md:mt-0 col-span-12">
                                        <div className="shadow sm:rounded-md sm:overflow-hidden">
                                            <div className="px-4 py-2 bg-white">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Documents</h3>
                                                <p className="mt-1 text-sm text-gray-600">
                                                    Please upload files and documents within the suggested range.
                                                </p>
                                            </div>
                                            <div className="px-4 py-2 bg-white space-y-6 sm:p-6">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        National Identity<sup className="text-red-600">*</sup>
                                                    </label>
                                                    {
                                                        idFile === null && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" name='nID' className="sr-only" onChange={handleFileChange} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG, PDF up to 1MB
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        idFile !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <i className="las la-link text-gray-400 text-lg"></i>
                                                                <span className="ml-2 flex-1 w-0 truncate">
                                                                    {idFile.name}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono" onClick={() => handleViewDoc('doca')}>
                                                                    <span>View</span>
                                                                </label>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" name='nID' className="sr-only" onChange={handleFileChange} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="px-4 py-2 bg-white space-y-6 sm:p-6">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Company RCCM<sup className="text-red-600">*</sup>
                                                    </label>
                                                    {
                                                        rccmFile === null && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" name='rccm' className="sr-only" onChange={handleFileChange} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG, PDF up to 1MB
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        rccmFile !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <i className="las la-link text-gray-400 text-lg"></i>
                                                                <span className="ml-2 flex-1 w-0 truncate">
                                                                    {rccmFile.name}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono" onClick={() => handleViewDoc('docb')}>
                                                                    <span>View</span>
                                                                </label>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" name='rccm' className="sr-only" onChange={handleFileChange} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="px-4 py-2 bg-white space-y-6 sm:p-6">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Comapny Logo<sup className="text-red-600">*</sup>
                                                    </label>
                                                    {
                                                        picFile === null && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" name='pic' className="sr-only" onChange={handleFileChange} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG, PDF up to 1MB
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        picFile !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <i className="las la-link text-gray-400 text-lg"></i>
                                                                <span className="ml-2 flex-1 w-0 truncate">
                                                                    {picFile.name}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono" onClick={() => handleViewDoc('docc')}>
                                                                    <span>View</span>
                                                                </label>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" name='pic' className="sr-only" onChange={handleFileChange} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <div className="px-4 py-2 bg-white space-y-6 sm:p-6">
                                                <div>
                                                    <label className="block text-sm font-medium text-gray-700">
                                                        Portfilio document <span className='text-xs text-gray-400'>(optional)</span>
                                                    </label>
                                                    {
                                                        pFile === null && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" name="pFile" className="sr-only" onChange={handleFileChange} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG, PDF up to 1MB
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        pFile !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <i className="las la-link text-gray-400 text-lg"></i>
                                                                <span className="ml-2 flex-1 w-0 truncate">
                                                                    {pFile.name}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono" onClick={() => handleViewDoc('docd')}>
                                                                    <span>View</span>
                                                                </label>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" name="pFile" className="sr-only" onChange={handleFileChange} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                                <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                    {!submit && "ADD Company"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Scrollbars>
        </div>
    );
}
