import React, { useState, useEffect } from 'react';

import { motion } from "framer-motion";
import { Scrollbars } from 'react-custom-scrollbars';
import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';
import Moment from 'moment';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import { Transition, Menu, Dialog } from '@headlessui/react';
import * as XLSX from 'xlsx';
import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useRecoilState } from 'recoil';
import { pathAtom, paramAtom, loadingAtom, tokenAtom } from '../../core/config/atoms';

import ServiceAsset from '../../services/serviceAsset';

import animPage from '../../assets/anim/anim-pageload.json';

export default function PanelEventInfo() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [param, setParam] = useRecoilState(paramAtom);
    const [token] = useRecoilState(tokenAtom);

    const [event, setEvent] = useState({});
    const [enrollmentList, setEnrollmentList] = useState([]);
    const [enrollment, setEnrollment] = useState([]);

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [query, setQuery] = useState('');

    const [isOpenSpot, setIsOpenSpot] = useState(false);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    let assetsService = new ServiceAsset();

    const formVSchema = Yup.object().shape({
        firstName: Yup.string().required('This information is required'),
        lastName: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address').required('This information is required'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            status: 'paid'
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let txn = `TXN${Math.round((new Date()).getTime() / 1000)}`;
            let body = {
                "uid": "Spot",
                "eid": event._id,
                "etype": "event",
                "fname": values.firstName,
                "lname": values.lastName,
                "email": values.email,
                "phone": values.phone,
                "status": values.status,
                "txn": txn
            }
            assetsService.setEnrollment(body, token).then((res) => {
                if (res.status) {
                    setSubmit(false);
                    setIsOpenSpot(false);
                    alert.show("Spot enrollment added!", { type: 'success' });
                    loadData();
                } else {
                    setSubmit(false);
                    alert.show("Server error, please try again!", { type: 'error' });
                }
                resetForm();
                document.getElementById("spot-form").reset();
            });
        }
    });

    const goBack = () => {
        setPath({ main: path.main, sub: 'sa1', isToggle: true });
    }

    const handleOnChangeSearch = (e) => {
        var { value, name } = e.target;
        setQuery(value);
        if (value.length > 0) {
            let list = enrollmentList.filter((u) => {
                return u.fname.toLowerCase().includes(value.toLowerCase()) || u.lname.toLowerCase().includes(value.toLowerCase()) || u.email.toLowerCase().includes(value.toLowerCase()) || u.phone.toLowerCase().includes(value.toLowerCase())
            });
            setEnrollment(list);
        } else {
            setEnrollment(enrollmentList);
        }
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(enrollment);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "enrollment");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "enrollment.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['fname,lname,email,phone,enrollment,status'];
        let usersCsv = enrollment.reduce((acc, row) => {
            const { fname, lname, email, phone, createdAt, status } = row
            acc.push([fname, lname, email, phone, createdAt, status].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'enrollment.csv',
            fileType: 'text/csv',
        });
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const onDateChange = (start, end) => {
        setSDate(start);
        setEDate(end);
    }

    const updateStatus = (id, status) => {
        let txn = `TXN${Math.round((new Date()).getTime() / 1000)}`;
        let body = {
            id: id,
            status: status,
            txn: txn,
            uid: 'Spot',
            oid: event._id,
            type: 'event',
            fees: event.fees
        };
        assetsService.updateEnrollment(body, token).then((res) => {
            if (res['status']) {
                loadData();
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        });
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = { _id: param };
            var doc = await assetsService.getEvent(body, token);
            setEvent(doc.data[0]);

            let bodyA = { eid: param, etype: 'event' };
            var docA = await assetsService.getEnrollment(bodyA, token);
            setEnrollment(docA.data);
            setEnrollmentList(docA.data);

            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Dialog open={isOpenSpot} onClose={() => setIsOpenSpot(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add Enrollment &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpenSpot(false);
                            resetForm();
                            document.getElementById("spot-form").reset();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} autoComplete="off" id="spot-form">
                        <div className="mb-6">
                            <label className="block text-xs font-medium text-gray-700 font-mono">First name<sup className="text-red-600">*</sup></label>
                            <input type="text" id="firstName" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="First name" value={values.firstName} />
                            {(errors.firstName && touched.firstName) && <p className='text-xs text-red-400 mt-1'>{errors.firstName}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block text-xs font-medium text-gray-700 font-mono">Last name<sup className="text-red-600">*</sup></label>
                            <input type="text" id="lastName" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Last name" value={values.lastName} />
                            {(errors.lastName && touched.lastName) && <p className='text-xs text-red-400 mt-1'>{errors.lastName}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block text-xs font-medium text-gray-700 font-mono">Email id<sup className="text-red-600">*</sup></label>
                            <input type="text" id="email" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Email ID" value={values.email} />
                            {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block text-xs font-medium text-gray-700 font-mono">Phone number<sup className="text-red-600">*</sup></label>
                            <input type="text" id="phone" onChange={handleChange} autoComplete="given-name" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="Phone number" value={values.phone} />
                            {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                        </div>
                        {
                            event.fees != 0 && <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Payment Status<sup className="text-red-600">*</sup></label>
                                <select type="text" name="status" id="status" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" value={values.status} onChange={handleChange}>
                                    <option value="">-- Select Payment --</option>
                                    <option value="paid">With Payment</option>
                                    <option value="unpaid">Without Payment</option>
                                </select>
                            </div>
                        }
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "Book"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2 p-3">
                        <div className='col-span-12'>
                            <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                                <i className="las la-long-arrow-alt-left text-xl"></i>
                                <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all events</h4>
                            </div>
                            <div className="intro-y mt-4">
                                <h3 className="text-2xl font-sans font-bold text-gray-800">Event Report</h3>
                                <p className="mt-1 text-sm text-gray-600 mb-2">
                                    All information related to event.
                                </p>
                                <hr />
                            </div>
                        </div>
                        <div className='col-span-12'>
                        </div>
                        <div className='col-span-12'>
                            <div class="w-full mt-2">
                                <div className='w-full bg-white rounded-md p-4'>
                                    <div className='flex justify-between'>
                                        <h3 className='text-lg font-sans font-medium'>Event information</h3>
                                        <button className="w-[200px] py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4" onClick={() => {
                                            setIsOpenSpot(true);
                                        }}>Spot Enrollment</button>
                                    </div>
                                    <hr className='my-4' />
                                    <div className='grid grid-cols-12 gap-4'>
                                        <div className='col-span-3'>
                                            <img src={event.media === undefined ? 'https://via.placeholder.com/260x160' : event.media.thumbnail} alt="Event Banner" className='w-full h-full border border-gray-400 rounded' />
                                        </div>
                                        <div className='col-span-9'>
                                            <div className='grid grid-cols-12 gap-2'>
                                                <div className='col-span-4'>
                                                    <span className='text-sm font-normal text-gray-600'>Certificate</span>
                                                    <p className='text-base font-medium'>{event.certicate ? 'With Certificate' : 'Without Certificate'}</p>
                                                </div>
                                                <div className='col-span-8'>
                                                    <span className='text-sm font-normal text-gray-600'>Title</span>
                                                    <p className='text-base font-medium'>{event.title}</p>
                                                </div>
                                                <div className='col-span-2'>
                                                    <span className='text-sm font-normal text-gray-600'>Trainer</span>
                                                    <p className='text-base font-medium'>{event.trainer}</p>
                                                </div>
                                                <div className='col-span-2'>
                                                    <span className='text-sm font-normal text-gray-600'>Date</span>
                                                    <p className='text-base font-medium'>{Moment(event.date).format('DD, MMM yy').toString()}</p>
                                                </div>
                                                <div className='col-span-2'>
                                                    <span className='text-sm font-normal text-gray-600'>Place</span>
                                                    <p className='text-base font-medium'>{event.place}</p>
                                                </div>
                                                <div className='col-span-2'>
                                                    <span className='text-sm font-normal text-gray-600'>Fees ($)</span>
                                                    <p className='text-base font-medium text-green-600'>{event.fees} $</p>
                                                </div>
                                                <div className='col-span-12 mt-2'>
                                                    <span className='text-sm font-normal text-gray-600'>Location</span>
                                                    <p className='text-base font-medium'>{event.location}</p>
                                                </div>
                                                <div className='col-span-12 mt-2'>
                                                    <span className='text-sm font-normal text-gray-600'>Description</span>
                                                    <p className='text-base font-medium'>{event.description}</p>
                                                </div>
                                                <div className='col-span-12 mt-2'>
                                                    <span className='text-sm font-normal text-gray-600'>Outcome</span>
                                                    <p className='text-base font-medium'>{event.outcome}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-full bg-white rounded-md p-4 mt-4'>
                                    <h3 className='text-lg font-sans font-medium'>Report</h3>
                                    <hr className='my-4' />
                                    <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                                        <div className="col-span-12 cursor-pointer">
                                            <div className="py-4">
                                                <h3 className='text-lg font-sans font-medium'>ENROLLMENTS</h3>
                                                {
                                                    enrollment.length != 0 && <table className="min-w-full divide-y divide-gray-200 px-4">
                                                        <thead className="bg-gray-50">
                                                            <tr className=''>
                                                                <th scope="col" className="border-b border-gray-200" colSpan={6}>
                                                                    <div className='w-full grid grid-cols-12 gap-2'>
                                                                        <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                                            <div className='col-span-4'>
                                                                                <RangeDatePicker
                                                                                    startDate={sdate}
                                                                                    endDate={edate}
                                                                                    minDate={new Date(2022, 8, 1)}
                                                                                    maxDate={Moment().toDate()}
                                                                                    disabled={false}
                                                                                    onCloseCalendar={(e) => {
                                                                                        loadData();
                                                                                    }}
                                                                                    onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                                                    className=""
                                                                                    startWeekDay="monday"
                                                                                />
                                                                            </div>
                                                                            <div className='w-full flex col-span-8 items-center'>
                                                                                <div className='w-[80px]'>
                                                                                    <p>Search :</p>
                                                                                </div>
                                                                                <input type="text" value={query} onChange={handleOnChangeSearch} name='search' placeholder="Search by name, email, and phone." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                                            </div>
                                                                        </form>
                                                                        <div className='col-span-1 flex items-center'>
                                                                            <Menu>
                                                                                <Menu.Button>
                                                                                    <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                                                </Menu.Button>
                                                                                <Transition
                                                                                    enter="transition duration-100 ease-out"
                                                                                    enterFrom="transform scale-95 opacity-0"
                                                                                    enterTo="transform scale-100 opacity-100"
                                                                                    leave="transition duration-75 ease-out"
                                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                                    leaveTo="transform scale-95 opacity-0">
                                                                                    <Menu.Items>
                                                                                        <div
                                                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                                            <div>
                                                                                                <ul className='flex flex-col items-start p-2'>
                                                                                                    <Menu.Item>
                                                                                                        <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                                            exportToExcel();
                                                                                                        }}>
                                                                                                            <div className='flex items-center text-base'>
                                                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </Menu.Item>
                                                                                                    <Menu.Item>
                                                                                                        <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                                            exportToCsv();
                                                                                                        }}>
                                                                                                            <div className='flex items-center text-base'>
                                                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </Menu.Item>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </div>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Name
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Email ID
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Phone number
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Enrolled At
                                                                </th>
                                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                                    Status
                                                                </th>
                                                                <th scope="col" className="px-2 py-3"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="bg-gray-50 divide-y divide-gray-200">
                                                            {
                                                                enrollment.length !== 0 && enrollment.map((enroll) => {
                                                                    return <tr className="hover:bg-gray-100">
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                {enroll.fname} {enroll.lname}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <div className="text-sm font-bold text-gray-900">
                                                                                {enroll.email}
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                            <div>
                                                                                <div className="text-sm text-gray-500">
                                                                                    Ph.: +243 {enroll.phone.substring(1)}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                                            <div>
                                                                                <div className="text-sm text-gray-500">
                                                                                    {Moment(enroll.createdAt).format('MMM, DD yyyy')}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${enroll.status == 'paid' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                                                {enroll.status.toUpperCase()}
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <Menu>
                                                                                <Menu.Button>
                                                                                    <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                                                                </Menu.Button>
                                                                                <Transition
                                                                                    enter="transition duration-100 ease-out"
                                                                                    enterFrom="transform scale-95 opacity-0"
                                                                                    enterTo="transform scale-100 opacity-100"
                                                                                    leave="transition duration-75 ease-out"
                                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                                    leaveTo="transform scale-95 opacity-0">
                                                                                    <Menu.Items>
                                                                                        <div
                                                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                                            <div>
                                                                                                <ul className='flex flex-col items-start p-2'>
                                                                                                    {
                                                                                                        enroll.status == 'unpaid' && <Menu.Item>
                                                                                                            <li className='w-32 cursor-pointer hover:bg-blue-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                                                updateStatus(enroll._id, 'paid');
                                                                                                            }}>
                                                                                                                <div className='flex items-center text-base'>
                                                                                                                    <i className="las la-certificate text-lg"></i>
                                                                                                                    <p className='text-sm font-serif ml-2'>Paid</p>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                    {
                                                                                                        enroll.status == 'cancel' && <Menu.Item>
                                                                                                            <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md'>
                                                                                                                <div className='flex items-center text-base'>
                                                                                                                    <i className="las la-ban text-lg"></i>
                                                                                                                    <p className='text-sm font-serif ml-2'>NONE</p>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                    {
                                                                                                        enroll.status != 'cancel' && <Menu.Item>
                                                                                                            <li className='w-32 cursor-not-allowed hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                                                updateStatus(enroll._id, 'cancel');
                                                                                                            }}>
                                                                                                                <div className='flex items-center text-base'>
                                                                                                                    <i className="las la-trash text-lg"></i>
                                                                                                                    <p className='text-sm font-serif ml-2'>Cancel</p>
                                                                                                                </div>
                                                                                                            </li>
                                                                                                        </Menu.Item>
                                                                                                    }
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Menu.Items>
                                                                                </Transition>
                                                                            </Menu>
                                                                        </td>
                                                                    </tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>
                                                }
                                                {
                                                    enrollment.length == 0 && <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                                                        <p className='text-center'>No enrollments found.</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    );
}
