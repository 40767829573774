import React, { useState, useEffect } from 'react';

import { motion } from "framer-motion";
import Lottie from 'lottie-react';
import { Scrollbars } from 'react-custom-scrollbars';

import { useRecoilState } from 'recoil';
import { pathAtom, paramAtom, loadingAtom, tokenAtom } from '../../../core/config/atoms';

import { useAlert } from 'react-alert';

import ServiceUser from '../../../services/serviceUser';

import animPage from '../../../assets/anim/anim-pageload.json';

export default function PanelCustomerInfo() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [param, setParam] = useRecoilState(paramAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [token] = useRecoilState(tokenAtom);

    const [user, setUser] = useState({});

    const [tab, setTab] = useState('a');

    const alert = useAlert();
    const userService = new ServiceUser();

    const goBack = () => {
        setPath({ main: path.main, sub: 'sa1', isToggle: true });
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                '_id': param,
            };
            var res = await userService.getAllUsers(body, token);
            if (res['status']) {
                setTab('a');
                setUser(res.data[0]);
                setLoading(false);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2 p-3">
                    {
                        !loading && <>
                            <div className='col-span-12'>
                                <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                                    <i className="las la-long-arrow-alt-left text-xl"></i>
                                    <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all customers</h4>
                                </div>
                                <div className="intro-y mt-4">
                                    <h3 className="text-2xl font-sans font-bold text-gray-800">Customer Report</h3>
                                    <p className="mt-1 text-sm text-gray-600 mb-2">
                                        All information related to customer.
                                    </p>
                                    <hr />
                                </div>
                            </div>
                            <div className='col-span-12'>

                            </div>
                            <div className='col-span-12'>
                                <div class="w-full mt-2">
                                    <div className="p-2 mt-2">
                                        <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'a' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('a') }}>Profile</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'b' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'hover:bg-green-100 rounded-md'}`} onClick={() => { setTab('b') }}>Report</button></li>
                                        </ul>
                                    </div>
                                    {
                                        tab === 'a' && <div className='grid grid-cols-12 gap-2'>
                                            <div className='col-span-3 bg-white rounded-md p-4 h-96'>
                                                <img src={user.profile[0].fileurl} alt='Customer profile' className='w-full h-full rounded border-2' />
                                            </div>
                                            <div className='bg-white rounded-md p-4 col-span-9'>
                                                <h3 className='text-lg font-sans font-medium'>Customer profile</h3>
                                                <hr className='my-4' />
                                                <div className='grid grid-cols-12 gap-2'>
                                                    <div className='col-span-2'>
                                                        <span className='text-sm font-normal text-gray-600'>First name</span>
                                                        <p className='text-base font-medium'>{user.fname}</p>
                                                    </div>
                                                    <div className='col-span-2'>
                                                        <span className='text-sm font-normal text-gray-600'>Last name</span>
                                                        <p className='text-base font-medium'>{user.lname}</p>
                                                    </div>
                                                    <div className='col-span-2'>
                                                        <span className='text-sm font-normal text-gray-600'>Occupation</span>
                                                        <p className='text-base font-medium'>{user.occupation}</p>
                                                    </div>
                                                    <div className='col-span-2'>
                                                        <span className='text-sm font-normal text-gray-600'>Email id</span>
                                                        <p className='text-base font-medium'>{user.email === "" ? "N/A" : user.email}</p>
                                                    </div>
                                                    <div className='col-span-2'>
                                                        <span className='text-sm font-normal text-gray-600'>Phone number</span>
                                                        <p className='text-base font-medium'>+243 {user.phone}</p>
                                                    </div>
                                                    <div className='col-span-12 mt-4'>
                                                        <span className='text-sm font-normal text-gray-600'>Address</span>
                                                        <p className='text-base font-medium'>{user.address[0].address}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        tab === 'b' && <div className='w-full bg-white rounded-md p-4'>
                                            <h3 className='text-lg font-sans font-medium'>Report</h3>
                                            <hr className='my-4' />
                                            <div className='grid grid-cols-10 gap-2'>
                                                <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                                                    <div className="shadow bg-green-200 rounded-lg">
                                                        <div className="box p-5">
                                                            <div className="flex items-center">
                                                                <i className="las la-dollar-sign text-4xl 2xl:text-6xl text-green-600"></i>
                                                                <div className="ml-auto text-right">
                                                                    <div className="text-2xl font-bold leading-8">0</div>
                                                                    <div className="text-xs text-gray-600 mt-1">Total Earnnings</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                                <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                                                    <div className="shadow bg-primary-200 rounded-lg">
                                                        <div className="box p-5">
                                                            <div className="flex items-center">
                                                                <i className="las la-receipt text-4xl 2xl:text-6xl text-primary"></i>
                                                                <div className="ml-auto text-right">
                                                                    <div className="text-2xl font-bold leading-8">0</div>
                                                                    <div className="text-xs text-gray-600 mt-1">Total Orders</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                                <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                                                    <div className="shadow bg-red-200 rounded-lg">
                                                        <div className="box p-5">
                                                            <div className="flex items-center">
                                                                <i className="las la-calendar text-4xl 2xl:text-6xl text-red-600"></i>
                                                                <div className="ml-auto text-right">
                                                                    <div className="text-2xl font-bold leading-8">0</div>
                                                                    <div className="text-xs text-gray-600 mt-1">Payment Dues</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                                <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                                                    <div className="shadow bg-yellow-200 rounded-lg">
                                                        <div className="box p-5">
                                                            <div className="flex items-center">
                                                                <i className="las la-city text-4xl 2xl:text-6xl text-yellow-500"></i>
                                                                <div className="ml-auto text-right">
                                                                    <div className="text-2xl font-bold leading-8">0</div>
                                                                    <div className="text-xs text-gray-600 mt-1">Total Company</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                                <motion.div whileHover={{ scale: 1.05 }} className="col-span-2 cursor-pointer">
                                                    <div className="shadow bg-blue-200 rounded-lg">
                                                        <div className="box p-5">
                                                            <div className="flex items-center">
                                                                <i className="las la-user-tie text-4xl 2xl:text-6xl text-blue-500"></i>
                                                                <div className="ml-auto text-right">
                                                                    <div className="text-2xl font-bold leading-8">0</div>
                                                                    <div className="text-xs text-gray-600 mt-1">Total Technitian</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </motion.div>
                                            </div>
                                            <div className='grid grid-cols-4 lg:grid-cols-12 mt-6 mb-5 gap-2 xl:gap-5 ml-2'>
                                                <div className="col-span-12 cursor-pointer">
                                                    <div className="py-4">
                                                        <h3 className='text-lg font-sans font-medium'>Upcoming orders</h3>
                                                        <div className='text-sm text-blue-400 font-sans border border-dashed rounded p-2 mt-2'>
                                                            <p className='text-center'>No order found.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                </div>
            </Scrollbars>
        </div>
    )
}
