import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOrder {
    lookupService = new APIServiceLookUp();

    async getOrders(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAggOrders(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getAllAggOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addQutation(body, token) {
        var url = `${API_URL}order/addQutation`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return await axios.post(url, body, { headers: requestHeaders });
    }

    async addPayment(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/addPayment`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateOrder(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/updateOrder`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderByOid(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getAggOrdersByOID`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getReview(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getReview`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}