import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';

import { useRecoilState } from 'recoil';
import { loadingAtom, tokenAtom } from '../../core/config/atoms';

import Moment from 'moment';
import * as XLSX from 'xlsx';
import Lottie from 'lottie-react';
import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceOrder from '../../services/serviceOrder';
import ServiceUser from '../../services/serviceUser';

import 'react-google-flight-datepicker/dist/main.css';

import animPage from '../../assets/anim/anim-pageload.json';
import fileIcon from '../../assets/image/pdf.png';

import DigQuotation from './dailogs/DigQuotation';
import DigPayment from './dailogs/DigPayment';
import DigAssingment from './dailogs/DigAssingment';

export default function PanelOrders() {

    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [token] = useRecoilState(tokenAtom);

    const { t } = useTranslation();

    const [orders, setOrders] = useState([]);
    const [ordersView, setOrdersView] = useState([]);
    const [ordersList, setOrdersList] = useState([]);
    const [alltech, setAllTechs] = useState([]);

    const [order, setOrder] = useState({});

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [min, setMin] = useState(0);

    const [selection, setSelection] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [qutation, setQutation] = useState(false);
    const [payment, setPayment] = useState(false);
    const [assing, setAssing] = useState(false);

    const alert = useAlert();

    const orderService = new ServiceOrder();
    const userService = new ServiceUser();

    const changeTab = (val) => {
        setTab(val);
        let filtered = [];
        setQuery('');
        switch (val) {
            case "all":
                setOrders(ordersList);
                setOrdersView(ordersList);
                break;
            case "quotation":
                filtered = ordersList.filter(u => {
                    return u.status === 'quotation';
                });
                setOrders(filtered);
                setOrdersView(filtered);
                break;
            case "booked":
                filtered = ordersList.filter(u => {
                    return u.status === 'booked';
                });
                setOrders(filtered);
                setOrdersView(filtered);
                break;
            case "sitevisit":
                filtered = ordersList.filter(u => {
                    return u.status === 'sitevisit';
                });
                setOrders(filtered);
                setOrdersView(filtered);
                break;
            case "inprocess":
                filtered = ordersList.filter(u => {
                    return u.status === 'inprocess';
                });
                setOrders(filtered);
                setOrdersView(filtered);
                break;
            case "completed":
                filtered = ordersList.filter(u => {
                    return u.status === 'completed';
                });
                setOrders(filtered);
                setOrdersView(filtered);
                break;
            case "cancelled":
                filtered = ordersList.filter(u => {
                    return u.status === 'cancelled';
                });
                setOrders(filtered);
                setOrdersView(filtered);
                break;
            default:
                break;
        }
    }

    const handleOnChangeSearch = (e) => {
        var { value, name } = e.target;
        setQuery(value);
        if (value.length > 0) {
            let list = ordersView.filter((u) => {
                return u.oid.toLowerCase().includes(value.toLowerCase()) || u.user[0].phone.toLowerCase().includes(value.toLowerCase()) || u.status.toLowerCase().includes(value.toLowerCase())
            });
            setOrders(list);
        } else {
            setOrders(ordersView);
        }
    }

    const onDateChange = (start, end) => {
        setSDate(start);
        setEDate(end);
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportOrders = () => {
        let ords = [];
        orders.forEach((order) => {
            ords.push({
                oid: order.oid,
                username: order.user[0].fname + " " + order.user[0].lname,
                useremail: order.user[0].email,
                address: order.address.address,
                disc: order.disc,
                remark: order.remark,
                categorie: order.categorie[0].title,
                service: order.sub[0].title,
                bookingDate: order.bookingDate,
                timeslot: order.timeslot,
                nop: order.nop,
                total: order.total,
                tax: order.tax,
                rate: order.rating,
                pstatus: order.pstatus,
                status: order.status,
                createdAt: order.createdAt,
            });
        });
        return ords;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportOrders());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "orders");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "orders.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['oid,username,useremail,address,disc,remark,categorie,service,bookingDate,timeslot,nop,total,tax,rate,pstatus,status,createdAt'];
        let usersCsv = getExportOrders().reduce((rows, item) => {
            const { oid, username, useremail, address, disc, remark, categorie, service, bookingDate, timeslot, nop, total, tax, rate, pstatus, status, createdAt } = item
            rows.push([oid, username, useremail, address, disc, remark, categorie, service, bookingDate, timeslot, nop, total, tax, rate, pstatus, status, createdAt].join(','))
            return rows
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'orders.csv',
            fileType: 'text/csv',
        });
    }

    const getOrderIds = (ord) => {
        let ids = [];
        ord.users.map((u) => {
            ids.push(u.uid);
        });
        return ids;
    }

    const setSelOrder = (ord) => {
        let body = {
            'ids': getOrderIds(ord)
        }
        userService.getUsersByIds(body, token).then((res) => {
            setAllTechs(res.data);
            setOrder(ord);
            setSelection(true);
        });
    }

    const changeQuotation = (val) => {
        setQutation(val);
    }

    const changeOrder = (ordData) => {
        setIsProcessing(true);
        setOrder(ordData);
    }

    const changeOrderB = (ordData) => {
        setIsProcessing(false);
        setSelOrder(ordData);
    }

    const changePayment = () => {
        setMin(0);
        setPayment(false);
    }

    const changeAssing = () => {
        setAssing(false);
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                'gte': sdate,
                'lt': edate
            };
            var res = await orderService.getAggOrders(body, token);
            if (res['status']) {
                setTab('all');
                setOrders(res.data);
                setOrdersList(res.data);
                setOrdersView(res.data);
                setLoading(false);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
        })();
    }

    const getChipColorA = (status) => {
        switch (status) {
            case "quotation":
            case "sitevisit":
            case "booked":
                return "bg-blue-100 text-blue-800";
            case "cancelled":
                return "bg-red-100 text-red-800";
            default:
                return "bg-green-100 text-green-800";
        }
    }

    const getChipAssing = (status) => {
        switch (status) {
            case "completed":
                return "bg-green-100 text-green-800";
            case "assing":
                return "bg-yellow-100 text-yellow-800";
            case "cancelled":
                return "bg-red-100 text-red-800";
            case "selected":
            default:
                return "bg-blue-100 text-blue-800";
        }
    }

    const getTStatus = (id) => {
        return order.users.filter((u) => u.uid == id)[0].status;
    }

    const getPBGChip = (status) => {
        switch (status) {
            case 'notpaid':
                return 'bg-red-100 text-red-800';
            case 'paid':
                return 'bg-green-100 text-green-800';
            case 'partial':
                return 'bg-yellow-100 text-yellow-800';
            default:
                return 'bg-blue-100 text-blue-800';
        }
    }

    const removeAssing = (id) => {
        setIsProcessing(false);
        let us = [];
        order.users.forEach((u) => {
            if (u.uid != id) {
                us.push(u);
            }
        });
        let body = {
            "id": order._id,
            "data": {
                "users": [...us]
            }
        };
        orderService.updateOrder(body, token).then((e) => {
            orderService.getOrderByOid({ 'id': order.oid }, token).then((d) => {
                setSelOrder(d.data[0]);
                alert.show("Removed assinged technician to order.", { type: 'success' });
                setIsProcessing(true);
            });
        });
    }

    const confirmAssing = (id) => {
        setIsProcessing(false);
        let us = [];
        order.users.forEach((u) => {
            if (u.uid != id) {
                us.push(u);
            } else {
                u.status = 'assing';
                us.push(u);
            }
        });
        let body = {
            "id": order._id,
            "data": {
                "users": [...us]
            }
        };
        orderService.updateOrder(body, token).then((e) => {
            orderService.getOrderByOid({ 'id': order.oid }, token).then((d) => {
                setSelOrder(d.data[0]);
                alert.show("Confirmed assinged technician to order.", { type: 'success' });
                setIsProcessing(true);
            });
        });
    }

    const getTachSts = () => {
        let isDone = true;
        for (let i = 0; i < order.users.length; i++) {
            if (order.users[i].status != 'completed') {
                isDone = false;
                break;
            }
        }
        return isDone;
    }

    const markCompleted = () => {
        if (order.pstatus != "paid") {
            alert.show('Payment for this order is not been paid.', { type: 'error' });
        } else {
            for (var i = 0; i < order.users.length; i++) {
                order.users[i].status = "completed";
            }
            let body = {
                "id": order._id,
                "data": {
                    "status": "completed",
                    "users": order.users,
                }
            };
            orderService.updateOrder(body, token).then((e) => {
                orderService.getOrderByOid({ 'id': order.oid }, token).then((d) => {
                    setSelOrder(d.data[0]);
                    alert.show("Order marked as completed.", { type: 'success' });
                    setIsProcessing(true);
                });
            });
        }
    }

    const markCancelled = () => {
        if (order.quotations.length > 0) {
            order.quotations[0].status = "cancelled"
        }
        for (var i = 0; i < order.users.length; i++) {
            order.users[i].status = "cancelled";
        }
        let body = {
            "id": order._id,
            "data": {
                "status": "cancelled",
                "quotations": order.quotations,
                "users": order.users,
            }
        };
        orderService.updateOrder(body, token).then((e) => {
            orderService.getOrderByOid({ 'id': order.oid }, token).then((d) => {
                setSelOrder(d.data[0]);
                alert.show("Order marked as cancelled.", { type: 'success' });
                setIsProcessing(true);
            });
        });
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (Object.keys(order).length !== 0) {
            setIsProcessing(true);
        }
    }, [order]);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                !loading && <>
                    {qutation && <DigQuotation onQutation={changeQuotation} onOrder={changeOrder} status={qutation} oid={order.oid} paid={order.invoices.paid} />}
                    {payment && <DigPayment onPayment={changePayment} onOrder={changeOrder} status={payment} oid={order.oid} min={min} order={order} />}
                    {assing && <DigAssingment onAssing={changeAssing} onOrder={changeOrderB} status={assing} order={order} />}
                    <Dialog open={selection} onClose={() => {
                        setSelection(false);
                        setOrder({});
                    }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                        <div className='bg-white w-[600px] absolute right-0 h-screen p-[20px]'>
                            {
                                selection && <Scrollbars>
                                    {
                                        isProcessing && <>
                                            <div className='flex items-center justify-between text-2xl'>
                                                <h1 className='font-sans font-medium text-gray-900'>Order #{order.oid}</h1>
                                                <span onClick={() => {
                                                    setSelection(false);
                                                    setOrder({});
                                                }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                            </div>
                                            <div className='flex mt-4'>
                                                <p className='font-sans font-medium'>Status:
                                                    <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(order.status)}`}>
                                                        {order.status}
                                                    </span>
                                                </p>
                                                <p className='ml-8 font-sans font-medium'>Payment:
                                                    {order.pstatus === "notpaid" && <>&nbsp;<i className="las la-times-circle text-red-400"></i> <span className="text-gray-800 font-normal text-sm">Not Paid</span></>}
                                                    {order.pstatus === "paid" && <>&nbsp;<i className="las la-check-circle text-green-600"></i> <span className="text-green-600 font-bold text-sm">Paid</span></>}
                                                    {order.pstatus === "partial" && <>&nbsp;<i className="las la-check-circle text-yellow-400"></i> <span className="text-gray-800 font-normal text-sm">Partially Paid</span></>}
                                                </p>
                                            </div>
                                            <p className='font-sans font-medium mt-2 flex items-center'><i class="las la-calendar text-xl font-medium mr-1"></i>Schedule:&nbsp;<span className="text-gray-800 font-normal text-xs">{Moment(order.bookingDate).format('DD MMM')} - {order.timeslot}</span></p>
                                            <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                                <span>
                                                    <i className="las la-file-invoice-dollar mr-2 text-lg"></i>Order Summery
                                                </span>
                                                {
                                                    (order.pstatus != "paid" && order.status != "cancelled") && <Menu>
                                                        <Menu.Button>
                                                            <motion.i className="las la-ellipsis-v text-lg cursor-pointer"></motion.i>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            <Menu.Item>
                                                                                <li className='w-44 cursor-pointer hover:bg-green-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    if (order.status != 'inprocess') {
                                                                                        // setMin(order.invoices.pending / 2);
                                                                                        setMin(0);
                                                                                    }
                                                                                    setPayment(true);
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-dollar-sign"></i>
                                                                                        <p className='text-xs font-serif ml-2'>Add Payment</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                }
                                            </div>
                                            <div className='flex mt-4'>
                                                <img src={order.categorie[0].iconURL} className='w-12 h-12 bg-white rounded border p-2'></img>
                                                <div className='flex flex-col ml-4'>
                                                    <span className='text-sm font-medium'>{order.categorie[0].title}</span>
                                                    <span className='text-sm text-gray-600'>{order.sub[0].title}</span>
                                                </div>
                                                <div className='flex-grow'></div>
                                                <span className='text-lg text-green-800 mr-8'>${order.total.toFixed(2)}</span>
                                            </div>
                                            <div className='mt-4 mr-8'>
                                                <h3 className='text-sm font-medium'>Job Description</h3>
                                                <p className='text-xs font-medium text-gray-400'>{order.disc}</p>
                                            </div>
                                            <div className='w-full h-[1px] bg-gray-900 mt-4'></div>
                                            <div className='mt-4 flex justify-between mr-8'>
                                                <span className='text-sm'>Sub Total</span>
                                                <span className='text-sm font-medium'>${order.total.toFixed(2)}</span>
                                            </div>
                                            <div className='mt-2 flex justify-between mr-8'>
                                                <span className='text-sm'>Tax (%16)</span>
                                                <span className='text-sm font-medium'>${order.tax.toFixed(2)}</span>
                                            </div>
                                            <div className='mt-2 flex justify-between mr-8'>
                                                <span className='text-sm'>Paid</span>
                                                <span className='text-sm font-medium'>- ${order.invoices.paid.toFixed(2)}</span>
                                            </div>
                                            <div className='mt-2 flex justify-between mr-8'>
                                                <span className='text-sm'>Pending</span>
                                                <span className='text-sm font-medium text-red-600'>${order.invoices.pending.toFixed(2)}</span>
                                            </div>
                                            <div className='mt-4 flex justify-between mr-8'>
                                                <span className='text-sm font-medium'>Grand Total</span>
                                                <span className='text-sm font-medium'>${(order.tax + order.total).toFixed(2)}</span>
                                            </div>
                                            <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4'><i className="las la-user mr-2 text-lg"></i>Customer</div>
                                            <div className='flex mt-4'>
                                                <img src={order.user[0].profile[0].fileurl} className='w-12 h-12 bg-white rounded border'></img>
                                                <div className='flex flex-col ml-4'>
                                                    <span className='text-sm font-medium'>{order.user[0].fname} {order.user[0].lname}</span>
                                                    <span className='text-sm text-gray-600'>(+243) {order.user[0].phone}</span>
                                                </div>
                                            </div>
                                            <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4'><i className="las la-map-marker mr-2 text-lg"></i>Address</div>
                                            <div className='flex mt-4'>
                                                <div className='flex flex-col'>
                                                    <span className='text-sm font-medium'>{order.address.title}</span>
                                                    <span className='text-sm text-gray-600'>{order.address.address}</span>
                                                </div>
                                            </div>
                                            <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                                <span>
                                                    <i className="las la-user-alt mr-2 text-lg"></i>Assingment
                                                </span>
                                                {
                                                    (order.status != "cancelled" && order.status != "completed") && <Menu>
                                                        <Menu.Button>
                                                            <motion.i className="las la-ellipsis-v text-lg cursor-pointer"></motion.i>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            <Menu.Item>
                                                                                <li className='w-44 cursor-pointer hover:bg-blue-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    setAssing(true);
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-plus-square text-sm"></i>
                                                                                        <p className='text-xs font-serif ml-2'>Add Assingment</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                }
                                            </div>
                                            {
                                                alltech.length !== 0 && alltech.map((user) => {
                                                    return <div className='flex mt-4 items-center'>
                                                        <img src={user.profile[0].fileurl} className='w-12 h-12 bg-white rounded border'></img>
                                                        <div className='flex flex-col ml-4'>
                                                            <span className='text-sm font-medium'>{user.fname} {user.lname}</span>
                                                            <span className={`text-xs font-bold py-1 mt-1 rounded-full text-center uppercase ${getChipAssing(getTStatus(user._id))}`}>{getTStatus(user._id)}</span>
                                                        </div>
                                                        <div className='flex-grow'></div>
                                                        {getTStatus(user._id) == "selected" && <span className='mr-2 bg-yellow-100 w-8 h-8 flex items-center justify-center rounded-full cursor-pointer' onClick={() => { confirmAssing(user._id) }}><i className="las la-user-lock text-yellow-700 cursor-pointer"></i></span>}
                                                        {getTStatus(user._id) != "completed" && <span className='mr-8 bg-red-100 w-8 h-8 flex items-center justify-center rounded-full cursor-pointer' onClick={() => { removeAssing(user._id) }}><i className="las la-times text-red-700 cursor-pointer"></i></span>}
                                                    </div>
                                                })
                                            }
                                            {
                                                order.users.length === 0 && <div className='border border-dashed border-blue-600 flex items-center mt-4 justify-center text-sm rounded py-2'> NA </div>
                                            }
                                            <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center relative'>
                                                <span>
                                                    <i className="las la-file-invoice mr-2 text-lg"></i>Quotation
                                                    {order.quotations.length != 0 && <>(<span className='text-xs'>{order.quotations[0].status}</span>)</>}
                                                </span>
                                                {
                                                    (order.quotations.length == 0 && (order.status == "quotation" || order.status == "sitevisit")) && <Menu>
                                                        <Menu.Button>
                                                            <motion.i className="las la-ellipsis-v text-lg cursor-pointer"></motion.i>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-142px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            <Menu.Item>
                                                                                <li className='w-44 cursor-pointer hover:bg-blue-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    setQutation(true);
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-plus-square text-sm"></i>
                                                                                        <p className='text-xs font-serif ml-2'>Add Quotation</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                }
                                            </div>
                                            {order.quotations.length == 0 && <div className='border border-dashed border-blue-600 mt-4 flex items-center justify-center text-sm rounded py-2'> NA </div>}
                                            {
                                                order.quotations.length != 0 && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                                    <div className="w-0 flex-1 flex items-center cursor-pointer" onClick={() => {
                                                        window.open(order.quotations[0].fileurl);
                                                    }}>
                                                        <img src={fileIcon} alt="Icon Template" className='w-10 h-10' />
                                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                                            {order.quotations[0].msg}&nbsp;
                                                            <i className="las la-link text-gray-400 text-lg"></i>
                                                        </span>
                                                    </div>
                                                    <div className="ml-4 flex-shrink-0">
                                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                            <span>$ {order.quotations[0].amount}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (order.status == "inprocess") && <div className='mt-4'>
                                                    <button className='bg-green-600 w-full p-2 rounded-md text-white text-sm' onClick={() => {
                                                        markCompleted();
                                                    }}>Mark Completed</button>
                                                </div>
                                            }
                                            {
                                                (order.status != "completed" && order.status != "cancelled") && <div className='mt-4'>
                                                    <button className='bg-red-600 w-full p-2 rounded-md text-white text-sm' onClick={() => {
                                                        markCancelled();
                                                    }}>Mark Cancelled</button>
                                                </div>
                                            }
                                            <div className='mt-4'></div>
                                        </>
                                    }
                                    {
                                        !isProcessing && <>
                                            <div className='flex items-center justify-center w-full h-full'>
                                                <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                                            </div>
                                        </>
                                    }
                                </Scrollbars>
                            }
                        </div>
                    </Dialog>
                    <Scrollbars>
                        <div className="grid grid-cols-12 grid-gap-2">
                            <div className="col-span-12 mt-2">
                                <div class="intro-y flex items-center justify-between h-10">
                                    <h2 class="text-2xl font-medium truncate ml-2">
                                        {t('PanelStr1')}
                                    </h2>
                                    <TopBar />
                                </div>
                                <div class="w-full mt-2">
                                    <div className="p-2 mt-2">
                                        <ul className="intro-y flex text-sm font-sans text-gray-600 border-b">
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'all' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('all') }}>All</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'quotation' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('quotation') }}>Quotation</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'sitevisit' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('sitevisit') }}>Site Visit</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'booked' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('booked') }}>Booked</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'inprocess' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('inprocess') }}>In Process</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'completed' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('completed') }}>Completed</button></li>
                                            <li className="mx-2"><button className={`py-2 px-2 ${tab === 'cancelled' ? 'text-green-600 font-semibold border-b-2 border-green-600 hover:text-green-500' : 'bg-white hover:bg-green-100 rounded-md'}`} onClick={() => { changeTab('cancelled') }}>Cancelled</button></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="intro-y col-span-12 grid grid-cols-12 gap-2">
                                <div className={`mt-2 col-span-12 shadow-md rounded-md overflow-hidden`}>
                                    <table className="min-w-full divide-y divide-gray-200 px-4">
                                        <thead className="bg-gray-50">
                                            <tr className=''>
                                                <th scope="col" className="border-b border-gray-200" colSpan={6}>
                                                    <div className='w-full grid grid-cols-12 gap-2'>
                                                        <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                            <div className='col-span-4'>
                                                                <RangeDatePicker
                                                                    startDate={sdate}
                                                                    endDate={edate}
                                                                    minDate={new Date(2022, 8, 1)}
                                                                    maxDate={Moment().toDate()}
                                                                    disabled={false}
                                                                    onCloseCalendar={(e) => {
                                                                        loadData();
                                                                    }}
                                                                    onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                                    className=""
                                                                    startWeekDay="monday"
                                                                />
                                                            </div>
                                                            <div className='w-full flex col-span-8 items-center'>
                                                                <div className='w-[80px]'>
                                                                    <p>Search :</p>
                                                                </div>
                                                                <input type="text" value={query} onChange={handleOnChangeSearch} name='search' placeholder="Search by order id, phone and status." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                            </div>
                                                        </form>
                                                        <div className='col-span-1 flex items-center'>
                                                            <Menu>
                                                                <Menu.Button>
                                                                    <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                                </Menu.Button>
                                                                <Transition
                                                                    enter="transition duration-100 ease-out"
                                                                    enterFrom="transform scale-95 opacity-0"
                                                                    enterTo="transform scale-100 opacity-100"
                                                                    leave="transition duration-75 ease-out"
                                                                    leaveFrom="transform scale-100 opacity-100"
                                                                    leaveTo="transform scale-95 opacity-0">
                                                                    <Menu.Items>
                                                                        <div
                                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                            <div>
                                                                                <ul className='flex flex-col items-start p-2'>
                                                                                    <Menu.Item>
                                                                                        <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                            exportToExcel();
                                                                                        }}>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                    <Menu.Item>
                                                                                        <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                            exportToCsv();
                                                                                        }}>
                                                                                            <div className='flex items-center text-base'>
                                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                                            </div>
                                                                                        </li>
                                                                                    </Menu.Item>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Order ID
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Service Details
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    User Details
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Location Details
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Payment Details
                                                </th>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                                    Order Status
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {
                                                orders.length !== 0 && orders.map((order, index) => {
                                                    return <tr className="cursor-pointer hover:bg-gray-100" onClick={() => { setSelOrder(order) }} key={index}>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div className="text-sm font-bold text-gray-900 uppercase">
                                                                Orders ID: {order.oid}
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div>
                                                                <div className="text-sm font-medium text-gray-900">
                                                                    {order.categorie[0].title}
                                                                </div>
                                                                <div className="text-sm text-gray-500">
                                                                    {order.sub[0].title}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                            <div>
                                                                <div className="text-sm font-bold text-gray-900">
                                                                    {order.user[0].fname} {order.user[0].lname}
                                                                </div>
                                                                <div className="text-sm font-medium text-gray-900">
                                                                    {order.user[0].email}
                                                                </div>
                                                                <div className="text-sm text-gray-500">
                                                                    Ph.: +243 {order.user[0].phone}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <div>
                                                                <div className="text-sm font-medium text-gray-900">
                                                                    {order.address.title}
                                                                </div>
                                                                <div className="text-sm text-gray-500">
                                                                    {order.address.address}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getPBGChip(order.pstatus)}`}>
                                                                {order.pstatus}
                                                            </span>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap">
                                                            <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(order.status)}`}>
                                                                {order.status}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            {
                                                orders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                                    <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                                        <div className="text-sm text-blue-600 text-center">
                                                            No data found.
                                                        </div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Scrollbars>
                </>
            }
        </div>
    )

}