import axios from 'axios';
import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAsset {
    lookupService = new APIServiceLookUp();

    async setEvent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/setEvent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getEvent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/getEvent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delEvent(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/delEvent`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getEnrollment(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/getEnrollment`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setEnrollment(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/setEnrollment`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateEnrollment(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/updateEnrollment`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setNotification(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/setNotification`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getNotification(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/getNotification`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delNotification(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/delNotification`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setTestimonial(body, token) {
        var url = `${API_URL}cms/setReview`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async getTestimonial(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}cms/getReview`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delTestimonial(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}cms/delReview`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addCategory(body, token) {
        var url = `${API_URL}category/addCategory`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async editCategory(body, token) {
        var url = `${API_URL}category/editCategory`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async getAllCategory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}category/getCategory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addSubCategory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}subcat/addSubCategory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getSubCategory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}subcat/getSubCategory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async editSubCategory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}subcat/editSubCategory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateCategory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}category/updateCategory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getFile(url, name) {
        var requestHeaders = {
            'Access-Control-Allow-Origin': '*/*'
        };
        var response = await axios.get(url, { headers: requestHeaders });

        let data = await response.blob();
        let metadata = {
            type: 'image/jpeg'
        };
        let file = new File([data], name, metadata);
        return file;
    }

    async addSlider(body, token) {
        var url = `${API_URL}cms/setSlider`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async getSlider(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}cms/getSlider`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delSlider(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}cms/delSlider`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addLogo(body, token) {
        var url = `${API_URL}cms/setSponcers`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async getLogo(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}cms/getSponcers`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delLogo(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}cms/delSponcers`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async addAd(body, token) {
        var url = `${API_URL}cms/setAdBanner`;
        var requestHeaders = {
            'Content-type': 'multipart/form-data',
            'Accept': '*/*',
            'Authorization': `bearer ${token}`,
        };
        return axios.post(url, body, { headers: requestHeaders });
    }

    async getAd(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}cms/getAdBanner`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delAd(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}cms/delAdBanner`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getFeedback(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/getFeedback`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getContacts(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}misc/getContacts`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getCReviews(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}course/getReview`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}