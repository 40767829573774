import React, { useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { pathAtom } from '../core/config/atoms';

import NavigationBar from '../core/widgets/ui/NavigationBar';

import FragmentHome from '../fragment/FragmentHome';
import FragmentOrder from '../fragment/FragmentOrder';
import FragmentUser from '../fragment/FragmentUser';
import FragmentCourses from '../fragment/FragmentCourses';
import FragmentReport from '../fragment/FragmentReport';
import FragmentSetting from '../fragment/FragmentSetting';

export default function PageHome() {

    const [path, setPath] = useRecoilState(pathAtom);

    useEffect(() => {
        if (path.sub == '') {
            setPath({ main: 'home', sub: 'sa1', isToggle: false });
        }
    });

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <NavigationBar />
            {(() => {
                switch (path['main']) {
                    case 'home':
                        return (<FragmentHome />);
                    case 'order':
                        return (<FragmentOrder />);
                    case 'user':
                        return (<FragmentUser />);
                    case 'courses':
                        return (<FragmentCourses />);
                    case 'report':
                        return (<FragmentReport />);
                    case 'setting':
                        return (<FragmentSetting />);
                    default:
                        return (
                            <div className="w-full h-full text-5xl flex items-center justify-center" style={{ height: "80vh" }}>
                                Coming Soon
                            </div>
                        );
                }
            })()}
        </div>
    )
}
