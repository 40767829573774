import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { authAtom, pathAtom, userAtom } from '../../config/atoms';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import icLogo from '../../../assets/image/logo-icon.png';

export default function NavigationBar() {

    const [authStatus, setAuthStatus] = useRecoilState(authAtom);
    const [path, setPath] = useRecoilState(pathAtom);
    const [userData, setUserData] = useRecoilState(userAtom);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
        setPath({ main: '', sub: '' });
    }

    const changeMainMenu = (main) => {
        setPath({ main: main, sub: 'sa1', isToggle: path.isToggle });
    }

    return (
        <>
            {authStatus === 'default' && <Navigate to="/" />}
            <div className="h-screen shadow-md bg-white hidden xs:block">
                <span className="cursor-pointer">
                    <div className="w-16 h-16">
                        <img src={icLogo} className="w-12 m-auto py-2" />
                    </div>
                </span>
                <ul className="flex flex-col items-center">
                    <Tippy content={t('nav1')} placement='right'>
                        <li className={`cursor-pointer w-16 ${path['main'] === 'home' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('home') }}>
                            <i className="las la-info w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav2')} placement='right'>
                        <li className={`cursor-pointer w-16 ${path['main'] === 'order' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('order') }}>
                            <i className="las la-paste w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav3')} placement='right'>
                        <li className={`cursor-pointer w-16 ${path['main'] === 'user' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('user') }}>
                            <i className="las la-user-tie w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav4')} placement='right'>
                        <li className={`cursor-pointer w-16 ${path['main'] === 'courses' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('courses') }}>
                            <i className="las la-book w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav5')} placement='right'>
                        <li className={`cursor-pointer w-16 ${path['main'] === 'report' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('report') }}>
                            <i className="las la-file-alt w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav6')} placement='right'>
                        <li className={`cursor-pointer w-16 ${path['main'] === 'setting' ? 'bg-green-600 hover:bg-green-700 text-white' : 'hover:bg-green-100 text-gray-900'}`} onClick={() => { changeMainMenu('setting') }}>
                            <i className="las la-cog w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav7')} placement='right'>
                        <li className={`cursor-pointer w-16 ${path['main'] === 'logout' ? 'bg-red-600 hover:bg-red-700 text-white' : 'hover:bg-red-400 text-gray-900 hover:text-white'}`} onClick={() => { logout('setting') }}>
                            <i className="las la-sign-out-alt w-full text-3xl text-center p-3"></i>
                        </li>
                    </Tippy>
                </ul>
            </div>
            <div className="fixed bottom-0 bg-white nShadow h-12 w-full rounded-t-md px-2 xs:hidden">
                <ul className="grid grid-cols-6 gap-2">
                    <Tippy content={t('nav1')} placement='top'>
                        <li className={`cursor-pointer col-span-1 ${path['main'] === 'home' ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('home') }}>
                            <i className="las la-info w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav2')} placement='top'>
                        <li className={`cursor-pointer col-span-1 ${path['main'] === 'order' ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('order') }}>
                            <i className="las la-paste w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav3')} placement='top'>
                        <li className={`cursor-pointer col-span-1 ${path['main'] === 'user' ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('user') }}>
                            <i className="las la-user-tie w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav4')} placement='top'>
                        <li className={`cursor-pointer col-span-1 ${path['main'] === 'courses' ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('courses') }}>
                            <i className="las la-book w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav5')} placement='top'>
                        <li className={`cursor-pointer col-span-1 ${path['main'] === 'report' ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('report') }}>
                            <i className="las la-file-alt w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                    <Tippy content={t('nav6')} placement='top'>
                        <li className={`cursor-pointer col-span-1 ${path['main'] === 'setting' ? 'text-green-600' : 'text-gray-600'}`} onClick={() => { changeMainMenu('setting') }}>
                            <i className="las la-cog w-full text-2xl text-center p-2"></i>
                        </li>
                    </Tippy>
                </ul>
            </div>
        </>
    )
}
