import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';

import { useAlert } from 'react-alert';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Scrollbars } from 'react-custom-scrollbars';
import Moment from 'moment';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, tokenAtom } from '../../core/config/atoms';

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import animPage from '../../assets/anim/anim-pageload.json';
import ServiceAsset from '../../services/serviceAsset';

const allowedExtensions = ["png", "jpeg", "pdf"];

export default function PanelTestimonial() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [token] = useRecoilState(tokenAtom);

    const { t } = useTranslation();

    const [reviews, setReviews] = useState([]);

    const [catIcon, setCatIcon] = useState(null);

    const [lang, setLang] = useState('En');

    const [isOpen, setIsOpen] = useState(false);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    let assetsService = new ServiceAsset();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        service: Yup.string().required('This information is required'),
        rateing: Yup.number().required('This information is required').max(5, 'Rating must be between 0 - 5').min(0, 'Rating must be between 0 - 5'),
        review: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            name: '',
            service: '',
            rateing: 0,
            review: '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            if (catIcon != null) {
                setSubmit(true);

                var formDataC = new FormData();

                formDataC.append("name", values.name);
                formDataC.append("service", values.service);
                formDataC.append("rate", values.rateing);
                formDataC.append("review", values.review);
                formDataC.append("lang", lang);
                formDataC.append("doc", catIcon);

                assetsService.setTestimonial(formDataC, token).then((res) => {
                    console.log(res);
                    if (res.status) {
                        resetForm();
                        setCatIcon(null);
                        setIsOpen(false);
                        loadData(lang);
                    } else {
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                    setSubmit(false);
                });
            } else {
                setSubmit(false);
                alert.show("Please select all the mandatory document!", { type: 'error' });
            }
        }
    });

    const deleteReview = (id) => {
        setLoading(true);
        let body = {
            id: id
        };
        assetsService.delTestimonial(body, token).then((res) => {
            setLoading(false);
            if (res.status) {
                loadData(lang);
            } else {
                alert.show("Server error, please try again!", { type: 'error' });
            }
        });
    }

    const handleFileChange = (e) => {
        var { value, name } = e.target;
        let isError = false;

        const inputFile = e.target.files[0];
        const fileExtension = inputFile?.type.split("/")[1];

        if (!allowedExtensions.includes(fileExtension)) {
            alert.show("Only .png file format is allowed, please try again!", { type: 'error' });
            isError = true;
        }

        if (isError) {
            return;
        }

        setCatIcon(inputFile);
    };

    const toggle = () => {
        setPath({ main: path.main, sub: path.sub, isToggle: !path.isToggle });
    }

    const loadData = (lan) => {
        setLoading(true);
        (async function () {
            let body = {
                'lang': lan,
            };
            var doc = await assetsService.getTestimonial(body, token);
            setReviews(doc.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData('En');
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Dialog open={isOpen} onClose={() => {
                setIsOpen(false);
                resetForm();
                setCatIcon(null);
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add Testimonial &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpen(false);
                            resetForm();
                            setCatIcon(null);
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Name<sup className="text-red-600">*</sup></label>
                            <input type="text" id="name" value={values.name} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Service<sup className="text-red-600">*</sup></label>
                            <input type="text" id="service" value={values.service} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.service && touched.service) && <p className='text-xs text-red-400 mt-1'>{errors.service}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Rateing<sup className="text-red-600">*</sup></label>
                            <input type="number" id="rateing" value={values.rateing} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.rateing && touched.rateing) && <p className='text-xs text-red-400 mt-1'>{errors.rateing}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Review<sup className="text-red-600">*</sup></label>
                            <textarea type="text" id="review" value={values.review} className="bg-gray-50 border h-32 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} />
                            {(errors.review && touched.review) && <p className='text-xs text-red-400 mt-1'>{errors.review}</p>}
                        </div>
                        <div className="mb-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    User Profile Pic<sup className="text-red-600">*</sup>
                                </label>
                            </div>
                            {
                                catIcon === null &&
                                <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                    <div className="space-y-1 text-center">
                                        <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        <div className="flex text-sm text-gray-600">
                                            <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <span>Upload a file</span>
                                                <input type="file" className="sr-only" required onChange={handleFileChange} />
                                            </label>
                                            <p className="pl-1">or drag and drop</p>
                                        </div>
                                        <p className="text-xs text-gray-500">
                                            PNG, JPG up to 512kb
                                        </p>
                                    </div>
                                </div>
                            }
                            {
                                catIcon !== null &&
                                <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                    <div className="w-0 flex-1 flex items-center">
                                        <img src={URL.createObjectURL(catIcon)} alt="Icon Template" className='w-10 h-10' />
                                        <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                            {catIcon.name}&nbsp;
                                            <i className="las la-link text-gray-400 text-lg"></i>
                                        </span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                            <span>Replace</span>
                                            <input type="file" className="sr-only" onChange={handleFileChange} />
                                        </label>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='flex items-end justify-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && "ADD"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10">
                                <h2 class="text-2xl font-medium truncate ml-2">
                                    <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => toggle()}></motion.i>  {t('pageA56')}
                                </h2>
                                <div className='flex'>
                                    <Menu>
                                        <Menu.Button>
                                            <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-language mr-2"></i> Language (<span className='text-xs'>{lang}</span>)</button>
                                        </Menu.Button>
                                        <Transition
                                            enter="transition duration-100 ease-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-100 opacity-100"
                                            leave="transition duration-75 ease-out"
                                            leaveFrom="transform scale-100 opacity-100"
                                            leaveTo="transform scale-95 opacity-0">
                                            <Menu.Items>
                                                <div
                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '52px', left: '-190px' }}>
                                                    <div>
                                                        <ul className='flex flex-col items-start p-2'>
                                                            <Menu.Item>
                                                                <li className={`w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md ${lang === 'En' ? 'flex justify-between items-center' : ''}`} onClick={() => {
                                                                    setLang('En');
                                                                    loadData('En');
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className={`text-sm font-serif ml-2 ${lang === 'En' ? 'font-medium text-green-600' : ''}`}>English</p>
                                                                    </div>
                                                                    {lang === 'En' && <i className={`las la-check-circle ${lang === 'En' ? 'font-medium text-green-600' : ''}`}></i>}
                                                                </li>
                                                            </Menu.Item>
                                                            <Menu.Item>
                                                                <li className={`w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md ${lang === 'Fr' ? 'flex justify-between items-center' : ''}`} onClick={() => {
                                                                    setLang('Fr');
                                                                    loadData('Fr');
                                                                }}>
                                                                    <div className='flex items-center text-base'>
                                                                        <p className={`text-sm font-serif ml-2 ${lang === 'Fr' ? 'font-medium text-green-600' : ''}`}>French</p>
                                                                    </div>
                                                                    {lang === 'Fr' && <i className={`las la-check-circle ${lang === 'Fr' ? 'font-medium text-green-600' : ''}`}></i>}
                                                                </li>
                                                            </Menu.Item>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4" onClick={() => {
                                        setIsOpen(true);
                                    }}><i className="las la-plus mr-2"></i> {t('pageA57')}</button>
                                    <TopBar />
                                </div>
                            </div>
                        </div>
                        <hr className='mt-2' />
                        <div className="col-span-12 mt-4">
                            <div className="intro-y bg-white w-full shadow rounded-md p-2">
                                <form className="flex">
                                    <div className="flex items-center rounded border p-2 h-9 w-1/2 shadow-inner">
                                        <i className="las la-search"></i>
                                        <input type="text" placeholder="Search by testimonial." className="w-full ml-2 font-sans text-sm outline-none" />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="intro-y mt-6 col-span-12">
                            <div className='bg-white w-full shadow rounded-md p-4 mt-2'>
                                <ul className='w-full'>
                                    {
                                        reviews.length !== 0 && reviews.map((review) => {
                                            return <li className='px-2 py-2'>
                                                <div className='flex'>
                                                    <div className='flex'>
                                                        <img src={review.profileurl} alt='Image icon' className='w-12 h-12 rounded-full border' />
                                                        <div className='ml-2'>
                                                            <h3 className="text-lg font-medium text-gray-900">{review.name}&nbsp;&nbsp;<span className='text-sm text-gray-600'>{review.service}</span></h3>
                                                            <p className='text-xs text-gray-600 mt-1'>{review.review}</p>
                                                        </div>
                                                    </div>
                                                    <div className='flex-grow'></div>
                                                    <div className='col-span-2 flex justify-end'>
                                                        <Popover className="relative">
                                                            <Popover.Button>
                                                                <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-xl"></motion.i>
                                                            </Popover.Button>
                                                            <Transition
                                                                enter="transition duration-100 ease-out"
                                                                enterFrom="transform scale-95 opacity-0"
                                                                enterTo="transform scale-100 opacity-100"
                                                                leave="transition duration-75 ease-out"
                                                                leaveFrom="transform scale-100 opacity-100"
                                                                leaveTo="transform scale-95 opacity-0">
                                                                <Popover.Panel>
                                                                    <div
                                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '6px', left: '-136px' }}>
                                                                        <div>
                                                                            <ul className='flex flex-col items-start p-2'>
                                                                                <li className='w-32 cursor-pointer hover:bg-red-100 px-2 py-1 rounded-md' onClick={() => {
                                                                                    deleteReview(review._id);
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <i className="las la-pen text-lg"></i>
                                                                                        <p className='text-sm font-serif ml-2'>{t('misc6')}</p>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </Popover.Panel>
                                                            </Transition>
                                                        </Popover>
                                                    </div>
                                                </div>
                                                <hr className='mt-2' />
                                                <div className='flex items-center justify-end mt-1 py-1'>
                                                    <div className='mr-2 bg-yellow-200 px-2 rounded-full flex items-center justify-center'>
                                                        <p className='text-xs text-right text-yellow-600'>{review.rateing}</p>&nbsp;
                                                        <i className="las la-star text-yellow-600"></i>
                                                    </div>
                                                    <p className='text-xs text-right text-gray-600'>{Moment(review.createdAt).format('DD/MM/YY, hh:mm:ss z').toString()}</p>
                                                </div>
                                            </li>
                                        })
                                    }
                                    {
                                        reviews.length === 0 && <li className='px-2'>
                                            <div className='flex items-center justify-center'>
                                                <h3 className="font-medium font-serif text-blue-600">{t('misc5')}</h3>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    )

}