import React, { useState, useEffect } from 'react';

import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';
import { useAlert } from 'react-alert';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import ServiceUser from '../../../services/serviceUser';
import ServiceOrder from '../../../services/serviceOrder';

import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';

export default function DigAssingment({ ...props }) {

    const [token] = useRecoilState(tokenAtom);

    const [users, setUsers] = useState([]);
    const [selUsers, setSelUsers] = useState([]);

    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    const orderService = new ServiceOrder();
    const userService = new ServiceUser();

    const assingUsers = () => {
        setSubmit(true);
        let us = [];
        selUsers.forEach((u) => {
            us.push({ "uid": u, "status": "selected" })
        });
        let body = {
            "id": props.order._id,
            "data": {
                "users": [...us]
            }
        };
        orderService.updateOrder(body, token).then((e) => {
            orderService.getOrderByOid({ 'id': props.order.oid }, token).then((d) => {
                setSubmit(false);
                setUsers([]);
                setSelUsers([]);
                props.onAssing();
                if (d.status) {
                    props.onOrder(d.data[0]);
                    alert.show("Assinged users to order.", { type: 'success' });
                } else {
                    alert.show("User assingment failed.", { type: 'error' });
                }
            });
        });
    }

    const updateUser = (id) => {
        let u = [...selUsers];
        if (u.includes(id)) {
            u.splice(u.indexOf(id), 1);
        } else {
            u.push(id);
        }
        setSelUsers(u);
    }

    const loadData = () => {
        (async function () {
            let u = [];
            props.order.users.map((user) => {
                u.push(user.uid);
            });
            setSelUsers(u);
            var body = {
                "status": "active",
                "tags": { "$in": props.order.sub[0].title }
            };
            userService.getAllUsers(body, token).then((res) => {
                if (res.status) {
                    setUsers(res.data);
                } else {
                    alert.show("Users fetch errpr.", { type: 'error' });
                }
            });
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Dialog open={props.status} onClose={() => {
            props.onAssing();
        }} className='overflow-y-auto overflow-x-hidden z-[100] w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                <div className='flex items-center justify-between'>
                    <h3 className="text-xl font-semibold text-gray-900">
                        Add Assingment &nbsp;
                    </h3>
                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                        props.onAssing();
                    }}>
                        <i className="las la-times text-sm text-black"></i>
                    </span>
                </div>
                <hr className='mt-2' />
                {
                    users.length === 0 && <div className='flex items-center justify-center border border-blue-400 border-dashed rounded mt-4 py-6'>
                        <p className='text-sm'>No Technician Available</p>
                    </div>
                }
                {
                    users.length !== 0 && users.map((user) => {
                        return <div className='mt-4 bg-gray-100 p-2 my-2 flex justify-between items-center cursor-pointer' onClick={() => {
                            updateUser(user._id);
                        }}>
                            <div className='flex items-start'>
                                <img src={user.profile[0].fileurl} className='w-12 h-12 bg-white rounded border'></img>
                                <div className='flex flex-col ml-4'>
                                    <span className='text-sm font-medium'>{user.fname} {user.lname} &nbsp;(<span className='text-green-600 uppercase text-xs'>{user.type}</span>)</span>
                                    <span className='text-sm text-gray-600'>(+243) {user.phone}</span>
                                </div>
                            </div>
                            {
                                selUsers.includes(user._id) && <div>
                                    <div className='bg-green-200 rounded-md h-8 w-8 text-green-600 text-xl felx items-center text-center justify-center'><i className="las la-check-circle"></i></div>
                                </div>
                            }
                        </div>
                    })
                }
                <div className='flex items-end mt-2'>
                    <button className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit} onClick={() => {
                        assingUsers();
                    }}>
                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                        {!submit && "DONE"}
                    </button>
                </div>
            </div>
        </Dialog>
    )
}
