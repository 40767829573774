import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

import { motion } from "framer-motion";
import { Menu, Transition } from '@headlessui/react';

import { useAlert } from 'react-alert';
import { Scrollbars } from 'react-custom-scrollbars';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import Moment from 'moment';
import * as XLSX from 'xlsx';
import Lottie from 'lottie-react';

import { useRecoilState } from 'recoil';
import { pathAtom, loadingAtom, tokenAtom } from '../../core/config/atoms';

import ServiceAsset from '../../services/serviceAsset';

import TopBar from '../../core/widgets/ui/TopBar';
import animPage from '../../assets/anim/anim-pageload.json';

export default function PanelFeedback() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [token] = useRecoilState(tokenAtom);

    const { t } = useTranslation();

    const [feeds, setFeeds] = useState([]);
    const [feedsList, setFeedsList] = useState([]);

    const [query, setQuery] = useState('');

    const [sdate, setSDate] = useState(Moment().startOf('month').toDate());
    const [edate, setEDate] = useState(Moment().toDate());

    const alert = useAlert();

    const assetService = new ServiceAsset();

    const toggle = () => {
        setPath({ main: path.main, sub: path.sub, isToggle: !path.isToggle });
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(feeds);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "feedback");
        //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
        //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
        XLSX.writeFile(workbook, "feedback.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['name,subject,message,sent'];
        let usersCsv = feeds.reduce((rows, item) => {
            const { name, subject, message, sent } = item
            rows.push([name, subject, message, sent].join(','))
            return rows
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'feedback.csv',
            fileType: 'text/csv',
        });
    }

    const handleOnChangeSearch = (e) => {
        var { value, name } = e.target;
        setQuery(value);
        if (value.length > 0) {
            let list = feedsList.filter((u) => {
                return u.name.toLowerCase().includes(value.toLowerCase()) || u.subject.toLowerCase().includes(value.toLowerCase())
            });
            setFeeds(list);
        } else {
            setFeeds(feedsList);
        }
    }

    const onDateChange = (start, end) => {
        setSDate(start);
        setEDate(end);
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {
                "gte": sdate.toISOString(),
                "lt": Moment(edate).add(1, 'day').toDate().toISOString()
            };
            console.log(body);
            var res = await assetService.getFeedback(body, token);
            console.log(res);
            if (res['status']) {
                let fe = [];
                for (var i = 0; i < res.data.length; i++) {
                    let doc = res.data[i];
                    fe.push({ name: doc.user[0].fname + " " + doc.user[0].lname, subject: doc.subject, message: doc.message, sent: doc.createdAt });
                }
                setFeeds(fe);
                setFeedsList(fe);
            } else {
                alert.show('Server error please try again', { type: 'error' });
            }
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            <Scrollbars>
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => toggle()}></motion.i> {t('pageA33')}
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                    </div>
                    <div className="col-span-12 mt-4 px-2">
                        <div className="w-full shadow-md rounded-md border">
                            <table className="min-w-full divide-y divide-gray-200 px-4">
                                <thead className="bg-gray-50">
                                    <tr className=''>
                                        <th scope="col" className="border-b border-gray-200" colSpan={4}>
                                            <div className='w-full grid grid-cols-12 gap-2'>
                                                <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                    <div className='col-span-4'>
                                                        <RangeDatePicker
                                                            startDate={sdate}
                                                            endDate={edate}
                                                            minDate={new Date(2022, 8, 1)}
                                                            maxDate={Moment().toDate()}
                                                            disabled={false}
                                                            onCloseCalendar={(e) => {
                                                                loadData();
                                                            }}
                                                            onChange={(startDate, endDate) => onDateChange(startDate, endDate)}
                                                            className=""
                                                            startWeekDay="monday"
                                                        />
                                                    </div>
                                                    <div className='w-full flex col-span-8 items-center'>
                                                        <div className='w-[80px]'>
                                                            <p>{t('misc4')} :</p>
                                                        </div>
                                                        <input type="text" value={query} onChange={handleOnChangeSearch} name='search' placeholder="Search by name and subject." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                    </div>
                                                </form>
                                                <div className='col-span-1 flex items-center'>
                                                    <Menu>
                                                        <Menu.Button>
                                                            <button className="w-full py-1 px-2 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4"><i className="las la-file-download mr-2"></i> {t('misc1')} </button>
                                                        </Menu.Button>
                                                        <Transition
                                                            enter="transition duration-100 ease-out"
                                                            enterFrom="transform scale-95 opacity-0"
                                                            enterTo="transform scale-100 opacity-100"
                                                            leave="transition duration-75 ease-out"
                                                            leaveFrom="transform scale-100 opacity-100"
                                                            leaveTo="transform scale-95 opacity-0">
                                                            <Menu.Items>
                                                                <div
                                                                    className='bg-white shadow-md rounded-md absolute' style={{ top: '32px', left: '-176px' }}>
                                                                    <div>
                                                                        <ul className='flex flex-col items-start p-2'>
                                                                            <Menu.Item>
                                                                                <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                    exportToExcel();
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">{t('misc2')}</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                <li className="w-40 cursor-pointer hover:bg-green-100 p-2 rounded-md" onClick={() => {
                                                                                    exportToCsv();
                                                                                }}>
                                                                                    <div className='flex items-center text-base'>
                                                                                        <p className="text-sm font-serif ml-2 font-normal">{t('misc3')}</p>
                                                                                    </div>
                                                                                </li>
                                                                            </Menu.Item>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </Menu>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Name
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Subject
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Message
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                            Sent At
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {
                                        feeds.length !== 0 && feeds.map((feed) => {
                                            return <tr className="hover:bg-gray-100">
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {feed.name}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {feed.subject}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">
                                                        {feed.message}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-bold text-gray-900">
                                                        {Moment(feed.sent).format('MMM, DD yyyy')}
                                                    </div>
                                                </td>
                                            </tr>
                                        })
                                    }
                                    {
                                        feeds.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                            <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                                <div className="text-sm text-blue-600 text-center">
                                                    {t('misc5')}
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Scrollbars>
        </div>
    )
}
