import React, { useState } from 'react';

import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dialog } from '@headlessui/react';
import { useAlert } from 'react-alert';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../core/config/atoms';

import ServiceOrder from '../../../services/serviceOrder';

import animBtnLoading from '../../../assets/anim/anim-btnLoading.json';
import fileIcon from '../../../assets/image/pdf.png';

export default function DigQuotation({ ...props }) {

    const [token] = useRecoilState(tokenAtom);

    const [qfile, setQFile] = useState(null);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    const orderService = new ServiceOrder();

    const formVSchema = Yup.object().shape({
        msg: Yup.string().required('This information is required'),
        amount: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            'msg': '',
            'amount': ''
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            var formDataC = new FormData();

            formDataC.append("id", props.oid);
            formDataC.append("msg", values.msg);
            formDataC.append("amount", values.amount);
            formDataC.append("paid", props.paid);
            formDataC.append("icon", qfile);

            orderService.addQutation(formDataC, token).then((d) => {
                resetForm();
                setSubmit(false);
                setQFile(null);
                if (d.data.status) {
                    props.onOrder(d.data.data);
                    alert.show("New Quotation created.", { type: 'success' });
                } else {
                    alert.show("Quotation creation error.", { type: 'error' });
                }
                props.onQutation(false);
            });
        }
    });

    return (
        <Dialog open={props.status} onClose={() => {
            props.onQutation(false);
            setQFile(null);
            resetForm();
        }} className='overflow-y-auto overflow-x-hidden z-[100] w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                <div className='flex items-center justify-between'>
                    <h3 className="text-xl font-semibold text-gray-900">
                        Add Quotation &nbsp;
                    </h3>
                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                        props.onQutation(false);
                        setQFile(null);
                        resetForm();
                    }}>
                        <i className="las la-times text-sm text-black"></i>
                    </span>
                </div>
                <hr className='mt-2' />
                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Quotation Message<sup className="text-red-600">*</sup></label>
                        <input type="text" value={values.msg} id="msg" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. some text" />
                        {(errors.msg && touched.msg) && <p className='text-xs text-red-400 mt-1'>{errors.msg}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Quotation Amount <span className='text-xs text-gray-400 font-normal'>(Tax inclusive)</span> <sup className="text-red-600">*</sup></label>
                        <input type="number" value={values.amount} id="amount" onChange={handleChange} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. $200" />
                        {(errors.amount && touched.amount) && <p className='text-xs text-red-400 mt-1'>{errors.amount}</p>}
                    </div>
                    <div className="mb-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Quotation File<sup className="text-red-600">*</sup>
                            </label>
                        </div>
                        {
                            qfile === null &&
                            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                <div className="space-y-1 text-center">
                                    <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                        <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                    <div className="flex text-sm text-gray-600">
                                        <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                            <span>Upload a file</span>
                                            <input type="file" className="sr-only" required onChange={(e) => setQFile(e.target.files[0])} />
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs text-gray-500">
                                        PDF up to 5 Mb
                                    </p>
                                </div>
                            </div>
                        }
                        {
                            qfile !== null &&
                            <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                <div className="w-0 flex-1 flex items-center">
                                    <img src={fileIcon} alt="Icon Template" className='w-10 h-10' />
                                    <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                        {qfile.name}&nbsp;
                                        <i className="las la-link text-gray-400 text-lg"></i>
                                    </span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                    <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                        <span>Replace</span>
                                        <input type="file" className="sr-only" onChange={(e) => setQFile(e.target.files[0])} />
                                    </label>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='flex items-end'>
                        <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "Add"}
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}
