import React from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { pathAtom } from '../core/config/atoms';

import { AnimatePresence, motion } from "framer-motion";

import PanelCourses from '../panels/course/PanelCourses';
import PanelEvents from '../panels/course/PanelEvents';
import PanelEventInfo from '../panels/course/PanelEventInfo';
import PanelCoursesAdd from '../panels/course/PanelCoursesAdd';
import PanelCourseInfo from '../panels/course/PanelCourseInfo';

import PanelDev from '../panels/dev/PanelDev';

export default function FragmentCourses() {

    const [path, setPath] = useRecoilState(pathAtom);

    const { t } = useTranslation();

    const itemVariants = {
        closed: {
            opacity: 0
        },
        open: { opacity: 1 }
    };

    const changeSubMenu = (sub) => {
        setPath({ main: path['main'], sub: sub, isToggle: path.isToggle });
    }

    return (
        <>
            <AnimatePresence>
                {
                    path.isToggle &&
                    <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={itemVariants}
                            className="text-xl lg:text-2xl font-medium pt-4 ml-6">{t('PanelStr7')}</motion.h1>
                        <motion.ul
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={itemVariants}
                            className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa1' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa1') }}>{t('PanelStr8')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa2' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa2') }}>{t('PanelStr9')}</li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {(() => {
                    switch (path.sub) {
                        case 'sa1':
                            return (<PanelEvents />);
                        case 'sa2':
                            return (<PanelCourses />);
                        case 'sa3':
                            return (<PanelEventInfo />);
                        case 'sa4':
                            return (<PanelCoursesAdd />);
                        case 'sa5':
                            return (<PanelCourseInfo />);
                        default:
                            return (<PanelDev />);
                    }
                })()}
            </div>
        </>
    )
}
