import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

export default function ComponentReportLogs() {
    return (
        <Scrollbars>
            <div>ComponentReportLogs</div>
        </Scrollbars>
    )
}
