import React, { useState, useEffect } from 'react';

import { Scrollbars } from 'react-custom-scrollbars';
import Lottie from 'lottie-react';
import { motion } from "framer-motion";
import { useAlert } from 'react-alert';

import { useRecoilState } from 'recoil';
import { pathAtom, paramAtom, loadingAtom, tokenAtom } from '../../core/config/atoms';

import ComponentCourseA from '../../components/course/ComponentCourseA';
import ComponentCourseB from '../../components/course/ComponentCourseB';
import ComponentCourseC from '../../components/course/ComponentCourseC';

import animPage from '../../assets/anim/anim-pageload.json';
import PanelDev from '../dev/PanelDev';
import ServiceCourse from '../../services/serviceCourse';

export default function PanelCoursesAdd() {

    const [path, setPath] = useRecoilState(pathAtom);
    const [loading, setLoading] = useRecoilState(loadingAtom);
    const [param, setParam] = useRecoilState(paramAtom);
    const [token] = useRecoilState(tokenAtom);

    const [course, setCourse] = useState({});

    const [cid, setCid] = useState('');

    const [step, setStep] = useState(1);

    const alert = useAlert();

    let courseService = new ServiceCourse();

    const onsetStep = (step, id) => {
        if (step === 4) {
            goBack();
        } else {
            populateData(step, id);
        }
    }

    const getStepStypeA = (st) => {
        switch (st) {
            case 1:
                if (step === 1) {
                    return 'bg-blue-600 text-white';
                } else {
                    if (cid === "") {
                        return 'bg-gray-200 text-black'
                    } else {
                        return course.step >= 1 ? 'bg-green-600 text-white' : 'bg-gray-200 text-black'
                    }
                }
            case 2:
                if (step === 2) {
                    return 'bg-blue-600 text-white';
                } else {
                    if (cid === "") {
                        return 'bg-gray-200 text-black'
                    } else {
                        return course.step >= 2 ? 'bg-green-600 text-white' : 'bg-gray-200 text-black'
                    }
                }
            case 3:
                if (step === 3) {
                    return 'bg-blue-600 text-white';
                } else {
                    if (cid === "") {
                        return 'bg-gray-200 text-black'
                    } else {
                        return course.step >= 3 ? 'bg-green-600 text-white' : 'bg-gray-200 text-black'
                    }
                }
        }
    }

    const getStepStypeB = (st) => {
        switch (st) {
            case 1:
                if (step === 1) {
                    return 'text-blue-600 font-medium';
                } else {
                    if (cid === "") {
                        return 'text-black'
                    } else {
                        return course.step >= 1 ? 'text-green-600 font-medium' : 'text-black'
                    }
                }
            case 2:
                if (step === 2) {
                    return 'text-blue-600 font-medium';
                } else {
                    if (cid === "") {
                        return 'text-black'
                    } else {
                        return course.step >= 2 ? 'text-green-600 font-medium' : 'text-black'
                    }
                }
            case 3:
                if (step === 3) {
                    return 'text-blue-600 font-medium';
                } else {
                    if (cid === "") {
                        return 'text-black'
                    } else {
                        return course.step >= 3 ? 'text-green-600 font-medium' : 'text-black'
                    }
                }
        }
    }

    const onStepClick = (st) => {
        if (cid !== "") {
            if (st <= course.step) {
                setStep(st);
            }
        }
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            if (param !== '') {
                populateData(1, param);
            } else {
                setLoading(false);
            }
        })();
    }

    const populateData = (step, id) => {
        setLoading(true);
        setCid(id);
        let body = {
            _id: id
        };
        courseService.getCourse(body, token).then((res) => {
            if (res.status) {
                setCourse(res.data[0]);
            }
            setStep(step);
            setLoading(false);
        });
    }

    const goBack = () => {
        setPath({ main: path.main, sub: 'sa2', isToggle: true });
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-screen p-2 flex flex-col bg-gray-50">
            {
                loading && <div className='flex items-center justify-center w-full h-full'>
                    <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                </div>
            }
            {
                !loading && <Scrollbars>
                    <div className="grid grid-cols-12 grid-gap-2 p-3">
                        <div className='col-span-12'>
                            <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                                <i className="las la-long-arrow-alt-left text-xl"></i>
                                <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all courses</h4>
                            </div>
                            <div className="intro-y mt-4">
                                <h3 className="text-2xl font-sans font-bold text-gray-800">Add Course</h3>
                                <p className="mt-1 text-sm text-gray-600 mb-2">
                                    All information related to course.
                                </p>
                                <hr />
                            </div>
                        </div>
                        <div className='col-span-12'>
                        </div>
                        <div className='col-span-12 grid grid-cols-3 gap-4'>
                            <motion.div whileHover={{ scale: 1.02 }} className='col-span-1 bg-white rounded-md flex items-center h-16 mt-4 pl-4 cursor-pointer' onClick={() => {
                                onStepClick(1);
                            }}>
                                <p className={`w-10 h-10 flex items-center justify-center font-sans font-medium text-xl rounded-full border border-black mx-2 ${getStepStypeA(1)}`}>1</p>
                                <p className={`${getStepStypeB(1)}`}>Course Basic Information</p>
                            </motion.div>
                            <motion.div whileHover={{ scale: 1.02 }} className='col-span-1 bg-white rounded-md flex items-center h-16 mt-4 pl-4 cursor-pointer' onClick={() => {
                                onStepClick(2);
                            }}>
                                <p className={`w-10 h-10 flex items-center justify-center font-sans font-medium text-xl rounded-full border border-black mx-2 ${getStepStypeA(2)}`}>2</p>
                                <p className={`${getStepStypeB(2)}`}>Course lessons</p>
                            </motion.div>
                            <motion.div whileHover={{ scale: 1.02 }} className='col-span-1 bg-white rounded-md flex items-center h-16 mt-4 pl-4 cursor-pointer' onClick={() => {
                                onStepClick(3);
                            }}>
                                <p className={`w-10 h-10 flex items-center justify-center font-sans font-medium text-xl rounded-full border border-black mx-2 ${getStepStypeA(3)}`}>3</p>
                                <p className={`${getStepStypeB(3)}`}>Course Extra Information</p>
                            </motion.div>
                        </div>
                        <div className='col-span-12 mt-6'>
                            {(() => {
                                switch (step) {
                                    case 1:
                                        return (<ComponentCourseA cid={cid} isEdit={false} isCourse={cid !== ""} onStep={onsetStep} />);
                                    case 2:
                                        return (<ComponentCourseB cid={cid} isEdit={false} isCourse={cid !== ""} onStep={onsetStep} />);
                                    case 3:
                                        return (<ComponentCourseC cid={cid} isEdit={false} isCourse={cid !== ""} onStep={onsetStep} />);
                                    default:
                                        return (<PanelDev />);
                                }
                            })()}
                        </div>
                    </div>
                </Scrollbars>
            }
        </div>
    );
}
