import React from 'react';

import PanelSetting from '../panels/settings/PanelSetting';

export default function FragmentSetting() {
    return (
        <>
            <div className='h-screen w-full'>
                <PanelSetting />
            </div>
        </>
    )
}
