import React from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { pathAtom } from '../core/config/atoms';

import { AnimatePresence, motion } from "framer-motion";

import PanelDev from '../panels/dev/PanelDev';
import PanelOverview from '../panels/home/PanelOverview';
import PanelNotification from '../panels/home/PanelNotification';
import PanelAssets from '../panels/home/PanelAssets';
import PanelTestimonial from '../panels/home/PanelTestimonial';
import PanelReview from '../panels/home/PanelReview';
import PanelCategory from '../panels/home/PanelCategory';
import PanelFeedback from '../panels/home/PanelFeedback';
import PanelContact from '../panels/home/PanelContact';

export default function FragmentHome() {

    const [path, setPath] = useRecoilState(pathAtom);

    const { t } = useTranslation();

    const itemVariants = {
        closed: {
            opacity: 0
        },
        open: { opacity: 1 }
    };

    const changeSubMenu = (sub) => {
        setPath({ main: path['main'], sub: sub, isToggle: path.isToggle });
    }

    return (
        <>
            <AnimatePresence>
                {
                    path.isToggle &&
                    <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={itemVariants}
                            className="text-xl lg:text-2xl font-medium pt-4 ml-6">{t('pageA24')}</motion.h1>
                        <motion.ul
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={itemVariants}
                            className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa1' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa1') }}>{t('pageA1')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa2' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa2') }}>{t('pageA25')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa3' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa3') }}>{t('pageA26')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa4' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa4') }}>{t('pageA27')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa5' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa5') }}>{t('pageA28')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa6' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa6') }}>{t('pageA29')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa7' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa7') }}>{t('pageA30')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa8' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa8') }}>{t('pageA31')}</li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {(() => {
                    switch (path.sub) {
                        case 'sa1':
                            return (<PanelOverview />);
                        case 'sa2':
                            return (<PanelNotification />);
                        case 'sa3':
                            return (<PanelReview />);
                        case 'sa4':
                            return (<PanelAssets />);
                        case 'sa5':
                            return (<PanelTestimonial />);
                        case 'sa6':
                            return (<PanelCategory />);
                        case 'sa7':
                            return (<PanelFeedback />);
                        case 'sa8':
                            return (<PanelContact />);
                        default:
                            return (<PanelDev />);
                    }
                })()}
            </div>
        </>
    )
}
