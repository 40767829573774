import React from 'react';
import { useTranslation } from "react-i18next";

import { useRecoilState } from 'recoil';
import { pathAtom } from '../core/config/atoms';

import { AnimatePresence, motion } from "framer-motion";

import PanelDev from '../panels/dev/PanelDev';
import PanelCustomer from '../panels/profiles/customers/PanelCustomer';
import PanelCustomerInfo from '../panels/profiles/temp/PanelCustomerInfo';

import PanelTechnician from '../panels/profiles/technicians/PanelTechnician';
import PanelTechnicianInfo from '../panels/profiles/technicians/PanelTechnicianInfo';
import PanelTechnicianAdd from '../panels/profiles/technicians/PanelTechnicianAdd';

import PanelCompany from '../panels/profiles/company/PanelCompany';
import PanelCompanyInfo from '../panels/profiles/temp/PanelCompanyInfo';
import PanelCompanyAdd from '../panels/profiles/company/PanelCompanyAdd';

import PanelUsers from '../panels/users/PanelUsers';
import PanelAddUsers from '../panels/users/PanelAddUsers';

export default function FragmentUser() {

    const [path, setPath] = useRecoilState(pathAtom);

    const { t } = useTranslation();

    const itemVariants = {
        closed: {
            opacity: 0
        },
        open: { opacity: 1 }
    };

    const changeSubMenu = (sub) => {
        setPath({ main: path['main'], sub: sub, isToggle: false });
    }

    return (
        <>
            <AnimatePresence>
                {
                    path.isToggle &&
                    <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1
                            initial="closed"
                            animate="open"
                            exit="closed"
                            variants={itemVariants}
                            className="text-xl lg:text-2xl font-medium pt-4 ml-6">{t('PanelStr2')}</motion.h1>
                        <motion.ul initial="closed"
                            animate="open"
                            exit="closed"
                            variants={itemVariants}
                            className="w-64 flex flex-col items-start mt-2 lg:mt-4">
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa1' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa1') }}>{t('PanelStr3')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa2' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa2') }}>{t('PanelStr4')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa3' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa3') }}>{t('PanelStr5')}</li>
                            <li className={`cursor-pointer text-base lg:text-lg ml-4 lg:ml-6 px-2 py-2 lg:py-3 ${path['sub'] === 'sa4' ? 'text-green-600 font-bold' : 'text-black hover:font-bold'}`} onClick={() => { changeSubMenu('sa4') }}>{t('PanelStr6')}</li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {(() => {
                    switch (path.sub) {
                        case 'sa1':
                            return (<PanelCustomer />);
                        case 'sa2':
                            return (<PanelTechnician />);
                        case 'sa3':
                            return (<PanelCompany />);
                        case 'sa4':
                            return (<PanelUsers />);
                        case 'sa5':
                            return (<PanelCustomerInfo />);
                        case 'sa6':
                            return (<PanelTechnicianAdd />);
                        case 'sa7':
                            return (<PanelCompanyAdd />);
                        case 'sa8':
                            return (<PanelTechnicianInfo />);
                        case 'sa9':
                            return (<PanelCompanyInfo />);
                        default:
                            return (<PanelDev />);
                    }
                })()}
            </div>
        </>
    )
}
