import React from 'react';

import { Scrollbars } from 'react-custom-scrollbars';

export default function ComponentReportTransaction() {
    return (
        <Scrollbars>
            <div>ComponentReportTransaction</div>
        </Scrollbars>
    );
}
