import React, { useState, useEffect } from 'react';

import { useAlert } from 'react-alert';
import { motion } from "framer-motion";
import { Menu, Transition, Dialog, Popover } from '@headlessui/react';
import Lottie from 'lottie-react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ProgressBar from "@ramonak/react-progress-bar";

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';
import ServiceCourse from '../../services/serviceCourse';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import fileIcon from '../../assets/image/file.png';
import axios from 'axios';
import { API_URL } from '../../services/models/AppConstent';

export default function ComponentCourseB({ ...props }) {

    const [token] = useRecoilState(tokenAtom);

    const [oCourse, setOCourse] = useState({});

    const [media, setMedia] = useState(null);

    const [index, setIndex] = useState(-1);
    const [progress, setProgress] = useState(0);

    const [isOpenMain, setIsOpenMain] = useState(false);
    const [isOpenSub, setIsOpenSub] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [isMain, setIsMain] = useState(true);

    const [isLoad, setIsLoad] = useState(true);
    const [submit, setSubmit] = useState(false);

    const alert = useAlert();

    var A = { lessonName: '' };
    var B = { sectionName: '', sectionType: '', discription: '', filename: '', fileurl: '', content: '' };

    let courseService = new ServiceCourse();

    const formVSchemaA = Yup.object().shape({
        lessonName: Yup.string().required('This information is required'),
    });

    const formVSchemaB = Yup.object().shape({
        sectionName: Yup.string().required('This information is required'),
        sectionType: Yup.string().required('This information is required'),
        discription: Yup.string().required('This information is required')
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm, setValues, setErrors } = useFormik({
        initialValues: isMain ? A : B,
        validationSchema: isMain ? formVSchemaA : formVSchemaB,
        enableReinitialize: true,
        onSubmit: values => {
            if (isMain) {
                setSubmit(true);
                let lessons = [...oCourse.lesson];
                lessons.push({ 'title': values.lessonName, sections: [] });
                let body = {
                    id: props.cid,
                    data: {
                        lesson: lessons
                    }
                }
                courseService.updateCourse(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenMain(false);
                        alert.show("Course information updated.", { type: 'success' });
                        loadData();
                    } else {
                        alert.show("Server error, please try again!", { type: 'error' });
                    }
                    setSubmit(false);
                });
            } else {
                let lessons = [...oCourse.lesson];
                setSubmit(true);
                if (values.sectionType == "pdf" || values.sectionType == "vfile") {
                    if (media == null) {
                        alert.show("Please select a file to continue!", { type: 'error' });
                        return;
                    }
                    var formData = new FormData();
                    formData.append("path", 'courses/' + props.cid + `/lesson${index}/`);
                    formData.append("file", media);
                    fnSubmit(formData, token);
                } else {
                    if (values.sectionType == "text" && values.content == "") {
                        setErrors({ ...errors, 'content': 'This information is required' });
                        setSubmit(false);
                        return;
                    }
                    if (values.sectionType == "link" && values.fileurl == "") {
                        setErrors({ ...errors, 'fileurl': 'This information is required' });
                        setSubmit(false);
                        return;
                    }
                    let section = {
                        sectionName: values.sectionName,
                        sectionType: values.sectionType,
                        discription: values.discription,
                        thumbnail: '',
                        filename: '',
                        status: values.sectionType == "link" ? 'Processing' : 'Active',
                        fileurl: values.sectionType == "link" ? values.fileurl : '',
                        content: values.sectionType == "text" ? values.content : '',
                    }
                    lessons[index].sections.push(section);
                    let body = {
                        id: props.cid,
                        type: values.sectionType,
                        lesson: index,
                        section: lessons[index].sections.length,
                        url: values.sectionType == "link" ? values.fileurl : '',
                        data: {
                            lesson: lessons
                        }
                    }
                    courseService.updateCourseSection(body, token).then((res) => {
                        if (res.status) {
                            setIsOpenSub(false);
                            setMedia(null);
                            setIndex(-1);
                            resetForm();
                            alert.show("Course information updated.", { type: 'success' });
                            loadData();
                        } else {
                            alert.show("Server error, please try again!", { type: 'error' });
                        }
                        setSubmit(false);
                    });
                }
            }
        }
    });

    const fnSubmit = (data, token) => {
        (async function () {
            setProgress(0);
            var url = `${API_URL}course/addMedia`;
            var requestHeaders = {
                'Content-type': 'multipart/form-data',
                'Accept': '*/*',
                'Authorization': `bearer ${token}`,
            };
            const response = await axios.post(url, data, {
                headers: requestHeaders,
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setProgress(progress);
                },
            });
            let section = {
                sectionName: values.sectionName,
                sectionType: values.sectionType,
                discription: values.discription,
                thumbnail: values.sectionType == "pdf" ? 'https://firebasestorage.googleapis.com/v0/b/afripro-prod.appspot.com/o/misc%2Fpdf.png?alt=media' : '',
                filename: response.data.data.filename,
                status: values.sectionType == "pdf" ? 'Active' : 'Processing',
                fileurl: response.data.data.fileurl,
                content: '',
            }
            let lessons = [...oCourse.lesson];
            lessons[index].sections.push(section);
            let body = {
                id: props.cid,
                type: values.sectionType,
                lesson: index,
                section: lessons[index].sections.length,
                url: response.data.data.fileurl,
                data: {
                    lesson: lessons
                }
            }
            courseService.updateCourseSection(body, token).then((res) => {
                if (res.status) {
                    setIsOpenSub(false);
                    setMedia(null);
                    setIndex(-1);
                    resetForm();
                    setProgress(0);
                    alert.show("Course information updated.", { type: 'success' });
                    loadData();
                } else {
                    alert.show("Server error, please try again!", { type: 'error' });
                }
                setSubmit(false);
            });
        })();
    }

    const deleteLesson = (index) => {
        let lessons = [...oCourse.lesson];
        lessons.splice(index, 1);
        let body = {
            id: props.cid,
            data: {
                lesson: lessons
            }
        }
        courseService.updateCourse(body, token).then((res) => {
            if (res.status) {
                alert.show("Course information updated.", { type: 'success' });
                loadData();
            } else {
                alert.show("Server error, please try again!", { type: 'error' });
            }
            setSubmit(false);
        });
    }

    const deleteSection = (index, sindex) => {
        let lessons = [...oCourse.lesson];
        lessons[index].sections.splice(sindex, 1);
        let body = {
            id: props.cid,
            data: {
                lesson: lessons
            }
        }
        courseService.updateCourse(body, token).then((res) => {
            if (res.status) {
                alert.show("Course information updated.", { type: 'success' });
                loadData();
            } else {
                alert.show("Server error, please try again!", { type: 'error' });
            }
            setSubmit(false);
        });
    }

    const getTypeTitle = (type) => {
        switch (type) {
            case "link":
                return "Video link";
            case "vfile":
                return "Video file";
            case "pdf":
                return "PDF file";
            case "text":
                return "Text content";
            default:
                return "NA";
        }
    }

    const loadData = () => {
        setIsLoad(true);
        (async function () {
            if (props.isCourse) {
                populateData();
            }
        })();
    }

    const populateData = () => {
        let body = {
            _id: props.cid
        };
        courseService.getCourse(body, token).then((res) => {
            if (res.status) {
                setOCourse(res.data[0]);
            }
            setIsLoad(false);
        });
    }

    const getBannerImage = (section) => {
        let src = "https://via.placeholder.com/140x100";
        if (section.sectionType == "text") {
            src = "https://firebasestorage.googleapis.com/v0/b/afripro-prod.appspot.com/o/misc%2Fnotes.png?alt=media";
        } else if (section.sectionType == "link" && section.status == "Active") {
            src = section.thumbnail;
        } else if (section.sectionType == "pdf" && section.status == "Active") {
            src = section.thumbnail;
        } else if (section.sectionType == "vfile" && section.status == "Active") {
            src = section.thumbnail;
        }
        return src;
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            <Dialog open={isOpenMain} onClose={() => setIsOpenMain(false)} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add Lesson &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpenMain(false);
                            resetForm();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Lesson Title<sup className="text-red-600">*</sup></label>
                            <input type="text" id="lessonName" value={values.lessonName} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" onChange={handleChange} />
                            {(errors.lessonName && touched.lessonName) && <p className='text-xs text-red-400 mt-1'>{errors.lessonName}</p>}
                        </div>
                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && !isUpdate && "Add"}
                                {!submit && isUpdate && "Update"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <Dialog open={isOpenSub} onClose={() => {
                setIsOpenSub(false);
                setMedia(null);
            }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                    <div className='flex items-center justify-between'>
                        <h3 className="text-xl font-semibold text-gray-900">
                            Add Sections &nbsp;
                        </h3>
                        <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                            setIsOpenSub(false);
                            setMedia(null);
                            setIndex(-1);
                            resetForm();
                        }}>
                            <i className="las la-times text-sm text-black"></i>
                        </span>
                    </div>
                    <hr className='mt-2' />
                    <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Section Title<sup className="text-red-600">*</sup></label>
                            <input type="text" id="sectionName" value={values.sectionName} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" onChange={handleChange} />
                            {(errors.sectionName && touched.sectionName) && <p className='text-xs text-red-400 mt-1'>{errors.sectionName}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Select type<sup className="text-red-600">*</sup></label>
                            <select type="text" id="sectionType" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" onChange={handleChange} defaultValue={values.sectionType}>
                                <option value="">-- Select Type --</option>
                                <option value="link" selected={values.sectionType == "link"}>Video Link</option>
                                <option value="vfile" selected={values.sectionType == "vfile"}>Video File</option>
                                <option value="pdf" selected={values.sectionType == "pdf"}>PDF File</option>
                                <option value="text" selected={values.sectionType == "text"}>Normal Text</option>
                            </select>
                            {(errors.sectionType && touched.sectionType) && <p className='text-xs text-red-400 mt-1'>{errors.sectionType}</p>}
                        </div>
                        <div className="mb-6">
                            <label className="block mb-2 text-sm font-medium text-gray-600">Section Description<sup className="text-red-600">*</sup></label>
                            <textarea type="text" id="discription" value={values.discription} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" onChange={handleChange} />
                            {(errors.discription && touched.discription) && <p className='text-xs text-red-400 mt-1'>{errors.discription}</p>}
                        </div>
                        {
                            values.sectionType === "text" && <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Section content<sup className="text-red-600">*</sup></label>
                                <textarea type="text" id="content" value={values.content} className="bg-gray-50 h-[140px] border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" onChange={handleChange} />
                                {(errors.content && touched.content) && <p className='text-xs text-red-400 mt-1'>{errors.content}</p>}
                            </div>
                        }
                        {
                            values.sectionType === "link" && <div className="mb-6">
                                <label className="block mb-2 text-sm font-medium text-gray-600">Video URL<sup className="text-red-600">*</sup></label>
                                <input type="text" id="fileurl" value={values.fileurl} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="" onChange={handleChange} />
                                {(errors.fileurl && touched.fileurl) && <p className='text-xs text-red-400 mt-1'>{errors.fileurl}</p>}
                            </div>
                        }
                        {
                            (values.sectionType === "vfile" || values.sectionType === "pdf") && <div className="mb-6">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Media file<sup className="text-red-600">*</sup>
                                    </label>
                                </div>
                                {
                                    media === null &&
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        <div className="space-y-1 text-center">
                                            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            <div className="flex text-sm text-gray-600">
                                                <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                    <span>Upload a file</span>
                                                    <input type="file" className="sr-only" required onChange={(e) => setMedia(e.target.files[0])} />
                                                </label>
                                                <p className="pl-1">or drag and drop</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    media !== null &&
                                    <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                        <div className="w-0 flex-1 flex items-center">
                                            <img src={fileIcon} alt="Icon Template" className='w-10 h-10' />
                                            <span className="ml-2 flex-1 w-0 truncate flex items-center">
                                                {media.name}&nbsp;
                                                <i className="las la-link text-gray-400 text-lg"></i>
                                            </span>
                                        </div>
                                        <div className="ml-4 flex-shrink-0">
                                            <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                <span>Replace</span>
                                                <input type="file" className="sr-only" onChange={(e) => setMedia(e.target.files[0])} />
                                            </label>
                                        </div>
                                    </div>
                                }
                            </div>
                        }

                        {
                            submit && <div className='my-3'>
                                <p className='text-xs text-gray-400'>Uploading....</p>
                                <div className='w-full bg-gray-300 mt-1' style={{ height: '4px' }}>
                                    <div style={{ width: `${progress}%`, transitionDuration: `4s` }} className="h-full transition-all bg-green-400"></div>
                                </div>
                            </div>
                        }

                        <div className='flex items-end'>
                            <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && !isUpdate && "Add"}
                                {!submit && isUpdate && "Update"}
                            </button>
                        </div>
                    </form>
                </div>
            </Dialog>
            <div className="w-full bg-white shadow-lg rounded-md p-4">
                <div className='flex items-center justify-between'>
                    <h3 className="text-xl font-semibold text-gray-900">
                        Course lessons &nbsp;
                    </h3>
                    <button className="py-1 px-3 h-10 rounded-md bg-green-600 hover:bg-green-800 text-white mr-4" onClick={() => {
                        setIsMain(true);
                        setIsOpenMain(true);
                    }}><i className="las la-plus mr-2"></i> Add Lesson</button>
                </div>
                <hr className='mt-2' />
                {
                    !isLoad && oCourse.lesson.map((lesson, ind) => {
                        return <div className='w-full bg-gray-50 p-2 my-2 rounded border hover:shadow'>
                            <div className='flex justify-between'>
                                <h2 className='text-base font-medium'>{lesson.title}</h2>
                                <div className='flex'>
                                    <span className='text-sm text-blue-600 cursor-pointer' onClick={() => {
                                        setIndex(ind);
                                        setIsMain(false);
                                        setIsOpenSub(true);
                                    }}>Add <i class="las la-pen bg-blue-200 p-1 rounded-full text-blue-600"></i></span>
                                    {
                                        lesson.sections.length == 0 && <span className='cursor-pointer ml-2' onClick={() => { deleteLesson(ind); }}>
                                            <i class="las la-trash bg-red-200 p-1 rounded-full text-red-600"></i>
                                        </span>
                                    }
                                </div>
                            </div>
                            <hr className='mt-2' />
                            {
                                lesson.sections.map((section, sind) => {
                                    return <div className='p-2 bg-white flex rounded border border-dashed my-1 hover:shadow'>
                                        <img src={getBannerImage(section)} alt='Banner image' className='rounded w-[140px] h-[100px]' />
                                        <div className='ml-2 flex flex-col'>
                                            <h3 className='font-medium'>{section.sectionName} | {section.discription}</h3>
                                            <p className='text-sm my-1 text-blue-600'>{getTypeTitle(section.sectionType)}</p>
                                            <p className={`text-xs font-medium rounded-full w-[80px] text-center ${section.status == "Active" ? 'bg-green-200 text-green-600' : 'bg-blue-200 text-blue-600'}`}>{section.status}</p>
                                        </div>
                                        <div className='flex-grow'></div>
                                        <span className='cursor-pointer ml-2' onClick={() => {
                                            deleteSection(ind, sind);
                                        }}>
                                            <i class="las la-trash bg-red-200 p-1 rounded-full text-red-600"></i>
                                        </span>
                                    </div>
                                })
                            }
                        </div>
                    })
                }
                <button type="submit" className="text-white bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center mt-2" onClick={() => {
                    props.onStep(3, props.cid);
                    alert.show("Course information updated.", { type: 'success' });
                }}>NEXT</button>
            </div>
        </>
    );
}
