import React from 'react';

import PanelOrders from '../panels/orders/PanelOrders';

export default function FragmentOrder() {
    return (
        <>
            <div className='h-screen w-full'>
                <PanelOrders />
            </div>
        </>
    )
}
